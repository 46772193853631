import React from 'react';
import { Trans } from 'react-i18next';
import HelpTooltipIcon from '../../../components/widgets/HelpTooltipIcon';

const ActionsHelpIcon = () => (
  <HelpTooltipIcon id="actions-icon-tooltip">
    <div>
      <b>pick</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.pick">ecom order was picked</Trans>
    </div>
    <div>
      <b>cannot-pick-cancel</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.cannotPickCancel">ecom order can't be picked, marked to Cancel</Trans>
    </div>
    <div>
      <b>cannot-pick-replace</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.cannotPickReplace">ecom order can't be picked, marked to Replace</Trans>
    </div>
    <div>
      <b>cancel-picking</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.cancelPicking">ecom order picking canceled</Trans>
    </div>
    <div>
      <b>remove-picking</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.removePicking">ecom order removed from trolley</Trans>
    </div>
    <div>
      <b>finalize-picking</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.finalizePicking">ecom order finalized</Trans>
    </div>
    <div>
      <b>add</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.add">order was put to stock</Trans>
    </div>
    <div className='text-start'>
      <b>give</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.give">order given to customer</Trans>
    </div>
    <div>
      <b>delete</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.delete">order removed</Trans>
    </div>
    <div>
      <b>move-finish</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.moveFinish">order moved</Trans>
    </div>
    <div>
      <b>cancel</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.cancel">order cancelled</Trans>
    </div>
    <div>
      <b>remove</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.remove">order removed from stock</Trans>
    </div>
    <div>
      <b>missing-item</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.missing">declared missing item during accepting tracking number</Trans>
    </div>
    <div>
      <b>found-item</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.found">found missing item during accepting tracking number</Trans>
    </div>
    <div>
      <b>unexpected-item</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.unexpectedItem">item not supposed to be at tracking number</Trans>
    </div>
    <div>
      <b>unexpected-order</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.unexpectedOrder">item from canceld/completed/wrong-store order</Trans>
    </div>
    <div>
      <b>problem-resolved</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.problemResolved">missing/unexpected problem marked resolved</Trans>
    </div>
    <div>
      <b>anomaly-exchange</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.anomalyExchange">free order created</Trans>
    </div>
    <div>
      <b>anomaly-exchange-cc1h</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.anomalyExchangeCC1h">free order created (C&C1h)</Trans>
    </div>
    <div>
      <b>to-mount</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.toMount">order marked to-mount</Trans>
    </div>
    <div>
      <b>mount</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.mount">order mounted</Trans>
    </div>
    <div>
      <b>back-to-sale</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.backToSale">order returned to stock of ther store</Trans>
    </div>
    <div>
      <b>give-automation</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.giveAutomation">order at lockers give automation</Trans>
    </div>
    <div>
      <b>print</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.print">print</Trans>
    </div>
    <div>
      <b>give-carrier</b>
      {' - '}
      <Trans i18nKey="movementsHistory.orders.actionsHelp.giveCarrier">order given to carrier</Trans>
    </div>
    <div></div>
  </HelpTooltipIcon>
);

export default ActionsHelpIcon;

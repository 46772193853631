import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePrintersState } from '../../store/printers';
import { getPrinters } from './api';
import useRequest from '../../functions/useRequest';
import { useUserReduxState } from '../../store/user';
import { findSelectedPrinter } from './helpers';
import MessageBox from '../widgets/MessageBox';

const defaultOptions = {
  width: 9.5,
  height: 2,
  padding: 0.2,
  fontsize: 20,
  ratio: 0.6,
  fit: false,
  qr: false,
  horizontal: false,
  format: 'A4',
  filetype: 'svg',
  orientation: 'portrait',
};

export const getOptions = (defaultOptions, key) => {
  const options = localStorage[key] ? JSON.parse(localStorage[key]) : defaultOptions;
  return options;
};

export const saveOptions = (key, options) => {
  localStorage[key] = JSON.stringify(options);
};

export const withPrintRequest = (Component, printRequest, printFileRequest) => (props) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const { selectedPrinterId } = props;
  const { user, updateTokens } = useUserReduxState();
  const [printSuccess, setPrintSuccess] = useState(false);
  const [printError, setPrintError] = useState(null);
  const [{ loading: printing }, doPrint] = useRequest(printRequest, {
    onSuccess: () => setPrintSuccess(true),
    onError: setPrintError,
  });
  const [{ loading: printingFile }, doPrintFile] = useRequest(printFileRequest, {
    onSuccess: (blob) => {
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL, '_blank')?.focus();
    },
    onError: setPrintError,
  });
  const isFilePrinting = !selectedPrinterId || selectedPrinterId === 'file';
  const printSuccessMessage = t('selectPrinter.printSuccess', {
    defaultValue: 'Sent to printer: {{printer}}',
    printer: selectedPrinterId,
  });

  return (
    <>
      <MessageBox
        show={printSuccess}
        onHide={() => setPrintSuccess(false)}
        title={t('shared.success')}
        body={printSuccessMessage}
      />
      <MessageBox
        icon={<i className="modal-header-icon text-danger vtmn-icon_cross_rounded" />}
        show={!!printError}
        onHide={() => setPrintError(null)}
        title={t('shared.error')}
        body={(printError && printError.message) || t('shared.error', 'Error')}
      />
      <Component
        printing={printing || printingFile}
        onPrint={(printParams) => {
          if (isFilePrinting) {
            if (printFileRequest) {
              doPrintFile({
                store,
                user,
                updateTokens,
                ...printParams,
              })
            }
          } else {
            doPrint({
              store,
              printer: selectedPrinterId,
              user,
              updateTokens,
              ...printParams,
            })
          }
        }}
        {...props}
      />
    </>
  )
};

export const withPrintState = (Component, printRequest) => (props) => {
  const { storageOptsKey } = props;
  const { store } = useParams();
  const { t } = useTranslation();
  const { user, updateTokens } = useUserReduxState();
  const { lastPrinter, setLastPrinter: saveLastPrinter } = usePrintersState()
  const initOptions = getOptions(defaultOptions, storageOptsKey || 'printLabelOptions');
  const [width, setWidth] = useState(initOptions.width);
  const [height, setHeight] = useState(initOptions.height);
  const [padding, setPadding] = useState(initOptions.padding);
  const [fontsize, setFontsize] = useState(initOptions.fontsize);
  const [ratio, setRatio] = useState(initOptions.ratio);
  const [fit, setFit] = useState(initOptions.fit);
  const [qr, setQr] = useState(initOptions.qr);
  const [horizontal, setHorizontal] = useState(initOptions.horizontal);
  const [format, setFormat] = useState(initOptions.format || 'A4');
  const [filetype, setFiletype] = useState(initOptions.filetype || 'svg');
  const [orientation, setOrientation] = useState(initOptions.orientation || 'portrait');

  const options = {
    width,
    height,
    padding,
    fontsize,
    ratio,
    fit,
    qr,
    horizontal,
    format,
    orientation,
    filetype,
  };
  const [previewOptions, setPreviewOptions] = useState(options);
  const [{
    loading: printersFetching,
    data: printers,
    error: printersError,
  }, fetchPrinters] = useRequest(getPrinters);
  const filePrinterLabel = t('selectPrinter.saveFile', 'Save File');
  const selectedPrinter = findSelectedPrinter(printers, lastPrinter, filePrinterLabel, true);
  const [selectedPrinterId, setSelectedPrinterId] = useState(selectedPrinter?.id);
  useEffect(() => {
    setSelectedPrinterId(selectedPrinter?.id);
  }, [selectedPrinter?.id])

  useEffect(() => {
    if (store) {
      fetchPrinters({ store, user, updateTokens });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <Component
      width={width}
      setWidth={setWidth}
      height={height}
      setHeight={setHeight}
      padding={padding}
      setPadding={setPadding}
      fontsize={fontsize}
      setFontsize={setFontsize}
      ratio={ratio}
      setRatio={setRatio}
      fit={fit}
      setFit={setFit}
      qr={qr}
      setQr={setQr}
      horizontal={horizontal}
      setHorizontal={setHorizontal}
      format={format}
      setFormat={setFormat}
      filetype={filetype}
      setFiletype={setFiletype}
      orientation={orientation}
      setOrientation={setOrientation}
      selectedPrinterId={selectedPrinterId}
      setSelectedPrinterId={setSelectedPrinterId}
      saveLastPrinter={saveLastPrinter}
      printers={printers}
      printersFetching={printersFetching}
      printersError={printersError}
      options={options}
      previewOptions={previewOptions}
      setPreviewOptions={setPreviewOptions}
      {...props}
    />
  );
}

import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import WithSidebar from '../WithSidebar';
import ImageReactModal from '../../components/widgets/ImageReactModal';
import Loader from '../../components/Loader';
import ScannerButton from '../../components/widgets/scanner/ScannerButton';
import ZXScanner from '../../components/widgets/scanner/ZXScanner';
import ZbarScanner from '../../components/widgets/scanner/ZbarScanner';
import BarcodeModal from '../../components/widgets/BarcodeModal';
import ErrorMessage from '../../components/widgets/errorMessage';
import { ConfirmPickOrder, ConfirmRemoveOrder } from '../Orders/ConfirmActions';
import { ParcelContentModal } from '../Orders/ParcelContent';
import { BatchPickingListCreateModal, PickingListCreateModal } from './PickingListModal';
import DeactivationError from '../Orders/DeactivationError';
import { pickOrder, removeOrderAddress } from '../Orders/api';
import { handleApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import OrderStock from './OrderStock';
import { logBarcodeReadEvent } from '../../functions/analytics';
import ItemToMountSwitch from '../OrdersMount/components/ItemToMountSwitch';
import ModalCompleteMovement from '../Orders/ModalCompleteMovement';

import '../../stylesheets/searchAddress.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ModalBackToSale from '../Orders/ModalBackToSale';

const serverUrl = process.env.REACT_APP_SERVERURL;

const addOrder = ({
  store,
  tracking_number,
  shipping_group_number,
  address,
  parcel_number,
  is_tomount,
  confirm_not_found,
  token,
  refreshToken,
  tokenExpireDate,
  updateTokens,
}) => {
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/stock_orders`;
  const body = JSON.stringify({
    tracking_number,
    shipping_group_number,
    address,
    parcel_number,
    is_tomount,
    confirm_not_found,
  });
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body,
  })
    .then(handleApiResponse);
};


class SearchAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "content",
      addressType: "",
      selectedBarcode: null,
      error: null,
      addressErrorMessage: null,
      addressLoading: false,
      disabled: false,
      inputValue: "",
      stock: [],
      stockOrders: [],
      activeTab: "items",  // items/orders
      valueModal: "",
      valueAddress: "",
      valueItem: "",
      valueQty: "",
      orderInputValue: "",
      parcelNumber: "",
      isTomount: false,
      itemAlertMessage: null,
      orderAlertMessage: null,
      orderNotFound: false,
      isLoading: false,
      toMountEnabled: false,
      reserveIsPickersOnly: false,
      stockOrdersEnabled: false,
      itemStorageEnabled: false,
      iAmPicker: false,
      iAmViewer: false,
      ordersApi: null,
      countryCode: null,
      imageModalData: null,
      parcelContentModalData: null,
      pickingOrder: null,
      confirmPickShow: false,
      confirmMoveShow: false,
      confirmRemoveShow: false,
      confirmBackToSaleShow: false,
      pickinglistShow: false,
      allPickinglistShow: false,
      deactivation: null,
      pickingStock: null,
    }
    this.itemScannerRef = React.createRef();
    this.orderScannerRef = React.createRef();
    this.addressScannerRef = React.createRef();
    this.addressInputRef = React.createRef();
    const { t } = props;
    this.addressTypes = {
      'address': t('address.addressTypes.address', 'Address'),
      'locker': t('address.addressTypes.locker', 'Locker'),
      'selfpickup': t('address.addressTypes.selfpickup', 'Self pick-up'),
    }
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const reserve_id = localStorage.getItem("reserve_id");
    await Promise.all([
      this.checkReserveIsPickersOnly(reserve_id),
      this.checkIfIAmPicker(this.props.user.email, reserve_id),
    ]);
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("address")) {
      const address = params.get("address");
      this.setState({
        address: address,
        valueAddress: address,
      });
      await this.getAddressContent(address, true);
    }
  }

  checkReserveIsPickersOnly = (reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(result => result.json())
      .then(result => {
        const reserveIsPickersOnly = result.text.view_only_by_default;
        const stockOrdersEnabled = result.text.stock_orders_enabled;
        const itemStorageEnabled = result.text.item_storage_enabled;
        const iAmViewer = result.text.viewer_right;
        const toMountEnabled = result.text.to_mount_enabled;
        const ordersApi = result.text.orders_api;
        const countryCode = result.text.country_code;
        this.setState({
          reserveIsPickersOnly,
          stockOrdersEnabled,
          itemStorageEnabled,
          iAmViewer,
          toMountEnabled,
          ordersApi,
          countryCode,
        });
      })
      .catch(error => {
        console.warn(`Error while checking if reserve ${reserve_id} is pickers only : ${error}`);
      })
  }

  checkIfIAmPicker = (email, reserve_id) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/pickers/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(result => result.json())
      .then(result => {
        this.setState({
          iAmPicker: result.pickers.map(picker => picker.email).includes(email)
        })
      })
      .catch(error => {
        console.warn(`Error while checking if ${email} is picker of reserve ${reserve_id} : ${error}`);
      })
  }

  handleChangeModal = (event) => {
    this.setState({
      valueModal: event.target.value
    })
  }

  handleChangeItem = (event) => {
    this.setState({
      valueItem: event.target.value
    })
  }

  handleChangeQty = (event) => {
    this.setState({
      valueQty: event.target.value
    })
  }

  handleChangeOrderInput = (event) => {
    this.setState({
      orderInputValue: event.target.value
    });
  }

  handleChangeParcelNumber = (event) => {
    this.setState({
      parcelNumber: event.target.value
    });
  }

  onItemScannerDetected = (code) => {
    this.getItem(code)
      .then(item => {
        this.setState({
          valueItem: item
        });
        document.getElementById("ItemQty").focus();
      });
  }

  onAddressScannerDetected = (code) => {
    this.setState({
      valueAddress: code,
      valueItem: "",
      valueQty: "",
      orderInputValue: "",
      parcelNumber: "",
      isTomount: false,
    });
    document.getElementById("buttonAddressSubmit").click();
  }

  onOrderScannerDetected = (code) => {
    let value = code;
    if (value.startsWith('tracking_number:')) {
      value = value.replace('tracking_number:', '')
    }
    this.setState({
      orderInputValue: value
    });
    document.getElementById("ParcelInput")?.focus();
  }

  setAddressState = ({ result, address, resetTab }) => {
    const { stockOrdersEnabled, itemStorageEnabled } = this.state;
    const { t } = this.props;
    let activeTab = stockOrdersEnabled ? "orders" : "items";
    if (!result.exists) {
      this.setState({
        addressErrorMessage: address + " " + t('address.unknown', 'unknown'),
        stock: [],
        stockOrders: [],
        disabled: result.disabled,
        addressLoading: false,
        orderInputValue: "",
        parcelNumber: "",
        isTomount: false,
        deactivation: null,
        addressType: "",
        selectedBarcode: null,
      });
    } else {
      if (itemStorageEnabled && result.stock && result.stock.length) {
        activeTab = "items";
      }
      if (stockOrdersEnabled && result.stock_orders && result.stock_orders.length) {
        activeTab = "orders";
      }
      this.setState({
        addressErrorMessage: null,
        stock: result.stock || [],
        stockOrders: result.stock_orders || [],
        disabled: result.disabled,
        addressLoading: false,
        orderInputValue: "",
        parcelNumber: "",
        isTomount: false,
        deactivation: null,
        addressType: result.address_type,
        selectedBarcode: null,
      });
    }
    if (resetTab) {
      this.setState({ activeTab });
    }
  }

  getAddressContent = async (address, resetTab) => {
    this.setState({ addressLoading: true, pickingOrder: null, error: null });
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    try {
      const result = await fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/addresses/${address}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      }).then(handleApiResponse);
      logBarcodeReadEvent({ success: result.exists, readingType: 'address', value: address });
      this.setAddressState({ result, address, resetTab });
    } catch (error) {
      logBarcodeReadEvent({ success: false, readingType: 'address', value: address });
      this.setState({
        error,
        addressErrorMessage: null,
        stock: [],
        stockOrders: [],
        disabled: false,
        addressLoading: false,
        orderInputValue: "",
        parcelNumber: "",
        isTomount: false,
        deactivation: null,
        addressType: "",
        selectedBarcode: null,
      });
      console.warn(`Error while retrieving stock for address ${address} : ${error}`);
    }
  }

  stockMovement(address, item_id, qtyInStock, qty, method) {
    const { t } = this.props;
    let myBody;
    let myUser = localStorage.getItem("email");
    if (this.props.user.email !== null || this.props.user.email !== undefined) {
      myUser = this.props.user.email;
    }
    if (method === "pick") {
      myBody = {
        item_id: item_id,
        qty: - qty,
        user: myUser
      };
    } else {
      myBody = {
        item_id: item_id,
        qty: qty,
        user: myUser
      };
    }
    // you can't pick more than the available stock
    if (method === "pick" && qty > qtyInStock) {
      this.setState({
        alertMessagePicking: t('address.insufficientStock', {
          defaultValue: 'Impossible to pick {{qty}} because the stock is insufficient ({{qtyInStock}})',
          qty,
          qtyInStock,
        })

      });
    } else {
      this.setState({ addressLoading: true });
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      return fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/addresses/${address}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        },
        method: "PUT",
        body: JSON.stringify(myBody)
      })
        .then(result => result.json())
        .then(result => {
          if (result.code !== "201" && result.text) {
            this.setState({ itemAlertMessage: result.text });
          } else {
            this.setState({ itemAlertMessage: null });
          }
          return this.getAddressContent(address, false);
        })
        .catch(error => {
          console.warn(`Error while putting stock in ${address} for item ${item_id} : ${error}`);
        })
    }
  }

  getItemFromEan(ean) {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/items/ean/${ean}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(result => result.json())
      .then(result => {
        return result
      })
  }

  getItem(input) {
    // if an ean or databar is entered, go to find the item_id
    const { t } = this.props;
    if (input.length === 13 || input.length === 30) {
      let ean13;
      if (input.length === 30) {
        ean13 = input.substr(3, 13);
      } else {
        ean13 = input;
      }
      return this.getItemFromEan(ean13)
        .then(response => {
          if (response.code === "200") { // ean found
            logBarcodeReadEvent({ success: true, readingType: 'item', value: input });
            this.setState({
              valueItem: response.text
            })
          } else {
            logBarcodeReadEvent({ success: false, readingType: 'item', value: input });
            this.setState({
              valueItem: t('address.eanNotFound', 'EAN not found'),
            });
          }
        })
    } else {
      return Promise.resolve(input);
    }
  }

  displayItemAlert() {
    if (this.state.itemAlertMessage !== null) {
      return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert" aria-label="Open">
          <p>{this.state.itemAlertMessage}</p>
          <button
            type="button"
            className="btn btn-secondary displayAlert"
            onClick={() => this.setState({
              itemAlertMessage: null
            })}
            aria-label="Close"
          >
            <Trans i18nKey="shared.ok">OK</Trans>
          </button>
        </div>
      );
    }
  }

  displayOrderAlert() {
    if (this.state.orderAlertMessage !== null) {
      return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert" aria-label="Open">
          <p>{this.state.orderAlertMessage}</p>
          <button
            type="button"
            className="btn btn-secondary displayAlert"
            onClick={() => this.setState({
              orderAlertMessage: null
            })}
            aria-label="Close"
          >
            <Trans i18nKey="shared.ok">OK</Trans>
          </button>
        </div>
      );
    }
  }

  modalOrderNotFound = () => (
    <div
      id="modal-confirm-add-order"
      key="error-modal" className="modal fade show" tabIndex="-1"
      role="dialog" aria-labelledby="modalConfirmAddTitle" aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <i className="modal-header-icon text-danger vtmn-icon_notif_alert"></i>
            <h5 className="modal-title" id="modalConfirmAddTitle">
              <Trans i18nKey="shared.warning">Warning</Trans>
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-confirm-add">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              {this.getNotFoundMessage()}
            </p>
            <p>
              <Trans i18nKey="address.shippingGroupConfirmInput">Please confirm you want to proceed.</Trans>
            </p>
            <p>
              {this.getInputLabel()}
              {': '}
              {this.state.orderInputValue}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-dismiss="modal"
            >
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                window.$("#modal-confirm-add-order").modal('hide');
                this.onAddOrder(true);
              }}
            >
              <Trans i18nKey="trackingNumber.assignAnyway">Assign anyway</Trans>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  itemAllocationModule = () => {
    if (
      this.state.address !== "content"
      && this.state.addressErrorMessage === null
      && this.state.itemStorageEnabled
      && (!this.state.reserveIsPickersOnly || this.state.iAmPicker || this.state.iAmViewer)
      && !this.state.disabled
    ) {
      const { t } = this.props;
      return (
        <form className="form" onSubmit={(event) => {
          event.preventDefault();
          if (this.state.valueItem === t('address.eanNotFound', 'EAN not found')) {
            return;
          } else if (this.state.valueQty === "") {
            this.setState({ itemAlertMessage: t('address.inputQty', 'Please input qty') });
          } else {
            this.stockMovement(this.state.address, this.state.valueItem, this.state.valueQty, this.state.valueQty, "add");
          }
        }}>
          <div className="form-group col row">
            <input
              type="text"
              className="form-control col"
              placeholder={t('address.item', 'Item')}
              value={this.state.valueItem}
              onChange={this.handleChangeItem}
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="none"
            />
            <ScannerButton scannerRef={this.itemScannerRef} />
            <input
              id="ItemQty"
              type="number"
              className="form-control col-2"
              placeholder={t('address.qty', 'Qty')}
              value={this.state.valueQty}
              onChange={this.handleChangeQty}
              min={1}
              step={1}
            />
            <button type="submit" id="buttonItemSubmit" className="btn btn-success">
              <Trans i18nKey="shared.ok">OK</Trans>
            </button>
          </div>
          {this.displayItemAlert()}
          <ZbarScanner
            readingType="item"
            ref={this.itemScannerRef}
            onDetected={this.onItemScannerDetected}
          />
        </form>
      )
    }
  }

  onPickOrder = (order) => {
    this.setState({ pickingOrder: order, confirmPickShow: true });
  }
  onMoveOrder = (order) => {
    this.setState({ pickingOrder: order, confirmMoveShow: true });
  }
  handleModalPickingList = (stockInfo) => {
    this.setState({ pickingStock: stockInfo, pickinglistShow: true });
  }
  handleShowBatchPickModal = () => {
    this.setState({ allPickinglistShow: true });
  }
  onRemoveOrder = (order) => {
    this.setState({ pickingOrder: order, confirmRemoveShow: true });
  }
  onBackToSale = (order) => {
    this.setState({ pickingOrder: order, confirmBackToSaleShow: true });
  }

  onPreviewBarcode = (code) => {
    this.setState({ selectedBarcode: code });
  }

  doPickOrder = async () => {
    const { address, pickingOrder } = this.state;
    const { user, match, updateTokens } = this.props;
    const { store } = match.params;
    this.setState({
      addressLoading: true,
      confirmPickShow: false,
      confirmRemoveShow: false,
      confirmBackToSaleShow: false,
    });
    try {
      const pickRes = await pickOrder({
        store,
        order_number: pickingOrder.order_number,
        id: pickingOrder.id,
        user,
        updateTokens,
      });
      await this.getAddressContent(address, false);
      this.setState({ deactivation: pickRes?.deactivation });
    } catch (error) {
      console.warn(`Error while picking order : ${error}`);
      const apiUnavailiable = error.status === 470;
      if (!apiUnavailiable) {
        const message = error.message || String(error);
        this.setState({ orderAlertMessage: message });
      }
      this.setState({ addressLoading: false });
      return this.getAddressContent(address, false);
    }
  }

  doRemoveOrderAddress = async () => {
    const { address, pickingOrder } = this.state;
    const { user, match, updateTokens } = this.props;
    const { store } = match.params;
    this.setState({
      addressLoading: true,
      confirmPickShow: false,
      confirmRemoveShow: false,
      confirmBackToSaleShow: false,
    });
    try {
      const pickRes = await removeOrderAddress({
        store,
        order_number: pickingOrder.order_number,
        id: pickingOrder.id,
        user,
        updateTokens,
      });
      await this.getAddressContent(address, false);
      this.setState({ deactivation: pickRes?.deactivation });
    } catch (error) {
      console.warn(`Error while picking order : ${error}`);
      const apiUnavailiable = error.status === 470;
      if (!apiUnavailiable) {
        const message = error.message || String(error);
        this.setState({ orderAlertMessage: message });
      }
      this.setState({ addressLoading: false });
      return this.getAddressContent(address, false);
    }
  }

  getAddressingParams = () => {
    const { orderInputValue, parcelNumber } = this.state;
    const parcel = parcelNumber || "1/1";
    const { ordersApi, countryCode } = this.state;
    if (ordersApi === 'stores_fulfiller_api') {
      return {
        shipping_group_number: null,
        parcel_number: orderInputValue,
        tracking_number: orderInputValue,
      }
    }
    if (ordersApi === 'hybrid_api') {
      const country = countryCode?.toLowerCase();
      const isCubeSG = orderInputValue.toLowerCase().startsWith(`sg${country}`)

      if (isCubeSG) {
        // cube SG
        return {
          shipping_group_number: orderInputValue.toLowerCase(),
          parcel_number: parcel,
          tracking_number: null,
        }
      }
      // chopper tracking number
      return {
        shipping_group_number: null,
        parcel_number: orderInputValue,
        tracking_number: orderInputValue,
      }
    }

    // cube SG
    return {
      shipping_group_number: orderInputValue,
      parcel_number: parcel,
      tracking_number: null,
    }
  }

  onAddOrder = (confirm) => {
    const { address, orderInputValue, isTomount } = this.state;
    const { user, match, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { store } = match.params;
    this.setState({ addressLoading: true });
    const { shipping_group_number, parcel_number, tracking_number } = this.getAddressingParams();
    addOrder({
      store,
      shipping_group_number,
      tracking_number,
      address,
      parcel_number,
      is_tomount: isTomount,
      confirm_not_found: confirm,
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    })
      .then(() => {
        logBarcodeReadEvent({ success: true, readingType: 'order', value: orderInputValue });
        return this.getAddressContent(address, false);
      })
      .catch((error) => {
        console.warn(`Error while adding order : ${error}`);
        const orderNotFound = error.status === 404;
        if (orderNotFound) {
          logBarcodeReadEvent({ success: false, readingType: 'order', value: orderInputValue });
          this.setState({ addressLoading: false });
          window.$("#modal-confirm-add-order").modal('show');
        } else {
          const message = error.message || String(error);
          this.setState({ orderAlertMessage: message, addressLoading: false });
          return this.getAddressContent(address, false);
        }
      });
  }

  getNotFoundMessage = () => {
    const { t } = this.props;
    const { ordersApi } = this.state;
    let label = ''
    if (ordersApi === 'stores_fulfiller_api') {
      label = t('address.trackingNumberNotFound', 'The tracking # was not found.');
    } else if (ordersApi === 'hybrid_api') {
      label = t('address.orderNotFound', 'The order was not found.');
    } else {
      // cube SG
      label = t('address.shippingGroupNotFound', 'The shipping group was not found.');
    }
    return label;
  }

  getInputLabel = () => {
    const { t } = this.props;
    const { ordersApi } = this.state;
    let label = ''
    if (ordersApi === 'stores_fulfiller_api') {
      label = t('trackingNumbers.trackingNumber', 'Tracking #');
    } else if (ordersApi === 'hybrid_api') {
      label = `${t('address.shippingGroup', 'Shipping group')} / ${t('trackingNumbers.trackingNumber', 'Tracking #')}`;
    } else {
      // cube SG
      label = t('address.shippingGroup', 'Shipping group');
    }
    return label;
  }

  setIsTomount =(val) => this.setState({ isTomount: val })

  orderAllocationModule = () => {
    if (
      this.state.address !== "content"
      && this.state.addressErrorMessage === null
      && this.state.stockOrdersEnabled
      && !this.state.disabled
    ) {
      const { ordersApi, isTomount, toMountEnabled } = this.state;
      const placeholder = this.getInputLabel();
      return (
        <form className="form">
          <div className="form-group col row align-items-center mt-2 mb-1">
            <ItemToMountSwitch
              active={toMountEnabled}
              isTomount={isTomount}
              setIsTomount={this.setIsTomount}
            />
          </div>
          <div className="form-group col row">
            <input
              type="text"
              className="form-control col"
              placeholder={placeholder}
              value={this.state.orderInputValue}
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="none"
              onChange={this.handleChangeOrderInput}
            />
            <ScannerButton scannerRef={this.orderScannerRef} />
            {ordersApi !== 'stores_fulfiller_api' && (
              <input
                id="ParcelInput"
                type="text"
                className="form-control col-2"
                placeholder="1/1"
                value={this.state.parcelNumber}
                onChange={this.handleChangeParcelNumber}
              />
            )}
            <button
              disabled={!this.state.orderInputValue}
              type="button"
              id="buttonOrderSubmit"
              className="btn btn-success"
              onClick={() => this.onAddOrder(false)}
            >
              <Trans i18nKey="shared.ok">OK</Trans>
            </button>
          </div>
          {this.displayOrderAlert()}
          <ZXScanner
            readingType="order"
            ref={this.orderScannerRef}
            onDetected={this.onOrderScannerDetected}
          />
        </form>
      );
    }
  }

  displayImage = (stockInfo) => (
    <div className="" style={{ width: '60px', height: '60px', paddingLeft: '10px' }}>
      {stockInfo.pixlId
        ? (
          <img
            key={stockInfo.pixlId}
            src={`https://contents.mediadecathlon.com/p${stockInfo.pixlId}/a.jpg?f=60x60`}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.setState({
                imageModalData: {
                  image: null,
                  pixlId: stockInfo.pixlId,
                  item: stockInfo.item,
                  item_description: stockInfo.item_description,
                  endOfLife: stockInfo.end_of_life,
                }
              })
            }}
          />
        )
        : null
      }
    </div>
  )

  onCloseAddToFullPickingList = () => {
    this.setState({
      pickinglistShow: false,
      allPickinglistShow: false,
      pickingStock: null,
    })
  }

  onCreatePickingListSuccess = async () => {
    this.onCloseAddToFullPickingList()
    await this.getAddressContent(this.state.address, false);
  }

  displayItemRow = (stockInfo) => {
    const { t } = this.props;
    const stockControlDisabled = this.state.reserveIsPickersOnly && !(this.state.iAmPicker || this.state.iAmViewer);
    return (
      <tr key={stockInfo.item}>
        <td className="p-0 to-center" width="60">{this.displayImage(stockInfo)}</td>
        <td className="text-center to-center">{stockInfo.item}</td>
        <td className="text-center to-center">
          {stockInfo.item_description}
          {stockInfo.end_of_life && (
            <span className="tag tag-warning ml-2">
              {t('items.endOfLife').toUpperCase()}
            </span>
          )}
        </td>
        <td className="text-center to-center">{
          stockControlDisabled
            ? stockInfo.qty
            : (
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="plusMinus vtmn-btn vtmn-btn_variant--primary vtmn-btn_with-only-icon vtmn-btn_size--small"
                  data-toggle="modal"
                  data-target={`#pick-${stockInfo.item}`}
                  disabled={this.state.disabled}
                  onClick={() => this.setState({ valueModal: stockInfo.qty, alertMessagePicking: null })}
                >
                  <i className="vtmn-icon_minus_v2"></i>
                </button>
                <div className="align-self-center">{stockInfo.qty}</div>
                <button
                  type="button"
                  className="plusMinus vtmn-btn vtmn-btn_variant--primary vtmn-btn_with-only-icon vtmn-btn_size--small"
                  data-toggle="modal"
                  data-target={`#add-${stockInfo.item}`}
                  disabled={this.state.disabled}
                  onClick={() => this.setState({ valueModal: 1 })}
                >
                  <i className="vtmn-icon_plus_v2"></i>
                </button>
              </div>
            )}
        </td>
        <td className="text-center align-middle">
          <button
            className="vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon"
            onClick={() => { this.handleModalPickingList(stockInfo) }}
            title={t('address.addMyList', 'Add to my picking list')}
          >
            <i className="vtmn-icon_cart"></i>
          </button>
        </td>
      </tr>
    )
  }

  displayItemStock = () => {
    const { t } = this.props;
    return (
      <>
        <div className="mobile-table-wrapper">
          <table id="table" className="table table-hover mobile-table">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th className="text-center align-middle">
                  <Trans i18nKey="address.itemCode">Item code</Trans>
                </th>
                <th className="text-center align-middle" style={{ lineBreak: 'anywhere' }}>
                  <Trans i18nKey="address.itemDescription">Item description</Trans>
                </th>
                <th className="text-center align-middle">
                  <Trans i18nKey="address.stock">Stock</Trans>
                  {' '}
                  <div className="font-weight-light">
                    <Trans i18nKey="address.total">Total</Trans>
                    {' '}
                    {this.getTotalQty()}
                  </div>
                </th>
                <th className="text-center align-middle" width={85}>
                  {this.state.stock.length > 0 && (
                    <button
                      className="vtmn-btn vtmn-btn_variant--ghost vtmn-btn_with-only-icon"
                      onClick={() => this.handleShowBatchPickModal()}
                      title={t('address.pickAll', 'Pick all items from address')}
                    >
                      <i className="vtmn-icon_cart"></i>
                    </button>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.stock.length === 0
                ? (
                  this.state.address === "content"
                    ? null
                    : <tr><td colSpan="5" className="text-center align-center"><Trans i18nKey="address.noStock">No stock</Trans></td></tr>
                )
                : this.state.stock.map(stockInfo => this.displayItemRow(stockInfo))
              }
            </tbody>
          </table>
        </div>
        {this.itemAllocationModule()}
      </>
    );
  };

  displayOrderStock = () => (
    <>
      <DeactivationError
        deactivation={this.state.deactivation}
        onClose={() => {
          this.setState({ deactivation: null });
        }}
      />
      <OrderStock
        stockOrders={this.state.stockOrders}
        address={this.state.address}
        onPickOrder={this.onPickOrder}
        onMoveOrder={this.onMoveOrder}
        onRemoveOrder={this.onRemoveOrder}
        onBackToSale={this.onBackToSale}
        disabled={this.state.disabled}
        onPreviewItems={(items, row) => {
          this.setState({
            parcelContentModalData: {
              items,
              orderNumber: row.order_number,
              customer: row.customer,
              mountStatus: row.mount_status,
            }
          });
        }}
        isLocker={this.state.addressType === 'locker'}
        onPreviewBarcode={this.onPreviewBarcode}
      />
      {this.orderAllocationModule()}
    </>
  );

  displayTabs = () => {
    const { activeTab } = this.state;
    const isItemsTab = activeTab === "items";
    const isOrdersTab = activeTab === "orders";
    return (
      <div
        className="btn-group btn-block mb-3"
        role="group"
        style={{
          paddingLeft: '2px',
          paddingRight: '2px',
        }}
      >
        <button
          type="button"
          className={`tab-button col m-0 btn ${isOrdersTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => this.setState({ activeTab: "orders" })}
        >
          <Trans i18nKey="address.orders">Orders</Trans>
        </button>
        <button
          type="button"
          className={`tab-button col m-0 btn ${isItemsTab ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => this.setState({ activeTab: "items" })}
        >
          <Trans i18nKey="address.items">Items</Trans>
        </button>
      </div>
    );
  }

  modalPick(stockInfo) {
    return (
      <div key={stockInfo.item} className="modal fade" id={`pick-${stockInfo.item}`} tabIndex="-1" role="dialog" aria-labelledby="modalPickTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalPickTitle">
                <Trans i18nKey="address.pickingItem">Picking item</Trans>
                {' '}
                {stockInfo.item}
                {' '}
                <br />
                <Trans i18nKey="address.pickingIn">in</Trans>
                {' '}
                {this.state.address}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id={`closeModalPick-${stockInfo.item}`}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="address.qtyPicked">Quantities picked</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" value={this.state.valueModal} onChange={this.handleChangeModal} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.stockMovement(this.state.address, stockInfo.item, stockInfo.qty, this.state.valueModal, "pick")} >
                <Trans i18nKey="shared.pick">Pick</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  modalPutMoreStock(stockInfo) {
    return (
      <div key={stockInfo.item} className="modal fade" id={`add-${stockInfo.item}`} tabIndex="-1" role="dialog" aria-labelledby="modalAddTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddTitle">
                <Trans i18nKey="address.addingStock">Adding stock</Trans>
                {' '}
                <br />
                {' '}
                <Trans i18nKey="address.addingIn">in</Trans>
                {' '}
                {this.state.address}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <Trans i18nKey="address.qtyAdded">Quantities added</Trans>
                  </span>
                </div>
                <input type="text" className="form-control m-0" value={this.state.valueModal} onChange={this.handleChangeModal} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.cancel">Cancel</Trans>
                {' '}
              </button>
              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.stockMovement(this.state.address, stockInfo.item, stockInfo.qty, this.state.valueModal, "add")} >
                <Trans i18nKey="shared.add">Add</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  searchAddress = (query) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({ isLoading: true, valueAddress: query });
    fetchWithJWT(`${serverUrl}/v1/${this.props.match.params.store}/addresses/query/${query}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
      .then(response => response.json())
      .then(json => this.setState({
        options: json,
        isLoading: false
      }));
  }

  getTotalQty = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if (this.state.stock.length === 0) {
      return "";
    }
    else {
      return (this.state.stock.map(e => e.qty)).reduce(reducer);
    }
  }

  onSelectAddress = (option) => {
    this.setState({
      valueAddress: (option && option.address) || "",
      addressType: (option && option.address_type) || "",
    }, () => {
      document.getElementById("buttonAddressSubmit").click();
    });
  }

  setValueAddress = (valueAddress) => this.setState({ valueAddress })

  render() {
    const {
      activeTab,
      addressLoading,
      stockOrdersEnabled,
      itemStorageEnabled,
      toMountEnabled,
      address,
      addressType,
      disabled,
      error,
      addressErrorMessage,
      isLoading,
      options,
      valueAddress,
      imageModalData,
      stock,
      confirmPickShow,
      confirmMoveShow,
      confirmRemoveShow,
      confirmBackToSaleShow,
      parcelContentModalData,
      pickinglistShow,
      allPickinglistShow,
      pickingStock,
      pickingOrder,
    } = this.state;
    const loading = addressLoading;
    const { t, user, updateTokens, logOut, match } = this.props;
    const { store } = match.params;
    const isItemsTab = activeTab === "items";
    const isOrdersTab = activeTab === "orders";
    const addressSelected = address !== "content";
    return (
      <WithSidebar user={user} updateTokens={updateTokens} logOut={logOut}>
        <div>
          <div className="jumbotron container">
            <div className="addresses-header">
              <h2 id="address" className="text-center">
                {this.addressTypes[addressType] || this.addressTypes.address}
                {' '}
                {address}
                {disabled ? ` (${t('shared.disabled', 'disabled')})` : null}

              </h2>
              <div className="addresses-header-actions">
                {toMountEnabled && (
                  <Link to={`/${store}/ordersMount`}>
                    <button
                      type="button"
                      id="ordersMount"
                      className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
                      title={t('orderMount.title', 'Items to mount')}
                    >
                      <img
                        className="img-icon"
                        alt="ordersMount"
                        src={'/icons/wrench.svg'}
                      />
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <p id="item_description" className="text-center">{addressErrorMessage}</p>
            <div className="form-group row col-12">
              <div className="input-group">
                <AsyncTypeahead
                  ref={this.addressInputRef}
                  id="item_code"
                  isLoading={isLoading}
                  onSearch={this.searchAddress}
                  onInputChange={this.setValueAddress}
                  placeholder={t('address.enterAddress', 'Enter an address ...')}
                  useCache={false}
                  minLength={2}
                  delay={500}
                  inputProps={{
                    autoCorrect: 'off',
                    autoComplete: 'off',
                    autoCapitalize: 'none',
                  }}
                  options={options || []}
                  filterBy={op => op.address}
                  labelKey={op => `${op.address}`}
                  onChange={([option]) => this.onSelectAddress(option)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 && !e.repeat) {
                      setTimeout(() => {
                        if (!addressLoading) {
                          document.getElementById("buttonAddressSubmit").click();
                        }
                      }, 100);
                    }
                  }}
                />
                <div className="input-group-append">
                  <button
                    id="buttonAddressSubmit"
                    className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_with-only-icon"
                    onClick={() => {
                      if (valueAddress !== "") {
                        if (this.addressInputRef && this.addressInputRef.current) {
                          this.addressInputRef.current.blur();
                        }
                        this.setState({ address: valueAddress });
                        this.getAddressContent(valueAddress, true);
                      }
                    }}
                  >
                    <i className="vtmn-icon_search"></i>
                  </button>
                  <ScannerButton scannerRef={this.addressScannerRef} />
                </div>
              </div>
            </div>
            <ZXScanner
              readingType="address"
              ref={this.addressScannerRef}
              onDetected={this.onAddressScannerDetected}
            />
            {loading && <Loader />}
            {addressSelected && !loading && error && <ErrorMessage error={error} />}
            {addressSelected && !loading && !error && stockOrdersEnabled && itemStorageEnabled && this.displayTabs()}
            {addressSelected && !loading && !error && itemStorageEnabled && isItemsTab && this.displayItemStock()}
            {addressSelected && !loading && !error && stockOrdersEnabled && isOrdersTab && this.displayOrderStock()}
          </div>
          {stock.map(stockInfo => this.modalPick(stockInfo))}
          {stock.map(stockInfo => this.modalPutMoreStock(stockInfo))}
          {this.modalOrderNotFound()}
          <ImageReactModal
            show={!!imageModalData}
            onHide={() => this.setState({ imageModalData: null })}
            pixlId={imageModalData?.pixlId}
            imageUrl={imageModalData?.image}
            item={imageModalData?.item}
            item_description={imageModalData?.item_description}
            tags={imageModalData?.endOfLife ? (
              <span className="tag tag-warning">
                {t('items.endOfLife').toUpperCase()}
              </span>
            ) : null}
          />
          <ConfirmPickOrder
            show={confirmPickShow}
            onHide={() => this.setState({ confirmPickShow: false })}
            onConfirm={this.doPickOrder}
          />
          <ModalCompleteMovement
            show={confirmMoveShow}
            onClose={() => this.setState({ confirmMoveShow: false })}
            stockOrderId={pickingOrder && pickingOrder.id}
            orderNumber={pickingOrder && pickingOrder.order_number}
            store={store}
            user={user}
            updateTokens={updateTokens}
            onSuccess={() => this.getAddressContent(address, false)}
          />
          <ConfirmRemoveOrder
            show={confirmRemoveShow}
            onHide={() => this.setState({ confirmRemoveShow: false })}
            onConfirm={this.doRemoveOrderAddress}
          />
          <ModalBackToSale
            store={store}
            order={{
              info: {
                order_number: pickingOrder?.order_number,
                items: pickingOrder?.items,
              },
            }}
            show={confirmBackToSaleShow}
            sg={pickingOrder?.shipping_group_number	}
            onClose={() => this.setState({ confirmBackToSaleShow: false })}
            onSuccess={() => {
              this.setState({ confirmBackToSaleShow: false });
              this.getAddressContent(address, false);
            }}
            user={user}
            updateTokens={updateTokens}
          />
          <ParcelContentModal
            show={!!parcelContentModalData}
            onClose={() => this.setState({ parcelContentModalData: null })}
            onPreview={(item) => {
              this.setState({
                imageModalData: {
                  image: item.image,
                  pixlId: null,
                  item: item.item_id,
                  item_description: item.item_description,
                }
              });
            }}
            orderNumber={parcelContentModalData?.orderNumber}
            customer={parcelContentModalData?.customer}
            items={parcelContentModalData?.items}
            mountStatus={parcelContentModalData?.mountStatus}
          />
          <BarcodeModal
            code={this.state.selectedBarcode}
            show={!!this.state.selectedBarcode}
            onHide={() => this.setState({ selectedBarcode: null })}
          />
          <PickingListCreateModal
            show={!!pickinglistShow}
            stock={pickingStock ? {
              address,
              stock_address_id: pickingStock.stock_address_id,
              item_id: pickingStock.item,
              item_description: pickingStock.item_description || '',
              qty: pickingStock.qty,
              qty_available: pickingStock.qty,
            } : null}
            store={this.props.match.params.store}
            user={this.props.user}
            updateTokens={updateTokens}
            onHide={() => this.onCloseAddToFullPickingList()}
            onSuccess={() => this.onCreatePickingListSuccess()}
          />
          <BatchPickingListCreateModal
            show={!!allPickinglistShow}
            stock={stock.map((stockInfo) => ({
              address,
              stock_address_id: stockInfo.stock_address_id,
              item_id: stockInfo.item,
              item_description: stockInfo.item_description || '',
              qty: stockInfo.qty,
              qty_available: stockInfo.qty,
            }))}
            store={this.props.match.params.store}
            user={this.props.user}
            updateTokens={updateTokens}
            onHide={() => this.onCloseAddToFullPickingList()}
            onSuccess={() => this.onCreatePickingListSuccess()}
          />
        </div>
      </WithSidebar>
    );
  }
}

export default withTranslation()(SearchAddress);

import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { useTranslation, Trans } from 'react-i18next';
import DaterangeFilter from '../../../components/widgets/filters/DaterangeFilter';
import InputFilter from '../../../components/widgets/filters/InputFilter';
import SelectFilter from '../../../components/widgets/filters/SelectFilter';
import AddressFilter from '../../../components/widgets/filters/AddressFilter';
import ExportButton from '../../../components/widgets/ExportButton';
import { getFiltersParams, getSortParams } from '../../../functions/getQueryString';
import { handleApiResponse } from '../../../functions/handleApiResponse';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import '../../../stylesheets/filters.css';

const exportData = (store) => (oauthToken, user, updateTokens, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.date_from) {
    formatedFilters.date_from = dateFormat(formatedFilters.date_from, "yyyy-mm-dd");
  }
  if (formatedFilters.date_to) {
    formatedFilters.date_to = dateFormat(formatedFilters.date_to, "yyyy-mm-dd");
  }
  const url = `${process.env.REACT_APP_SERVERURL}/v1/${store}/history/stock_orders/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
};

const hasFilters = (filters) => {
  const keys = Object.keys(filters).filter(x => x !== 'date_from' && x !== 'date_to');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
};

const Filters = ({
  user,
  updateTokens,
  filters,
  totals,
  setFilters,
  onClear,
  sort,
  shippingMethods,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState('partial');
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  const filtered = hasFilters(filters);
  const { store } = useParams();
  const memoizedExportData = useMemo(() => exportData(store), [store]);

  return (
    <div className={`card filter-card filter-card-${visibility} mb-3 mt-3`}>
      <div className="card-header">
        <button
          size="sm"
          className={`btn btn-primary btn-sm icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
          onClick={() => setVisibility(isVisible ? 'hidden' : 'partial')}
        >
          <i className="vtmn-icon_filter2"></i>
          {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
          <Trans i18nKey="filters.filters">Filters</Trans>
          {isVisible ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </button>
        <div className="spacer"/>
        <ExportButton
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportData}
        />
      </div>

      {isVisible && (
        <div className="card-body">
          <div className="row">
            <DaterangeFilter
              controlId="filter-date"
              label={t('movementsHistory.orders.date', 'Date')}
              valueFrom={filters.date_from}
              valueTo={filters.date_to}
              onChange={(date_from, date_to) => setFilters({
                ...filters,
                date_from,
                date_to,
              })}
            />

            <AddressFilter
              user={user}
              updateTokens={updateTokens}
              store={store}
              controlId="filter-address"
              label={t('movementsHistory.orders.address', 'Address')}
              value={filters.address}
              onChange={(address) => setFilters({
                ...filters,
                address,
              })}
            />

            <InputFilter
              controlId="filter-order-number"
              label={t('movementsHistory.orders.order', 'Order')}
              value={filters.order_number}
              onChange={(order_number) => setFilters({ ...filters, order_number })}
            />

            {isFull && (
              <>
                <InputFilter
                  controlId="filter-shipping-group-number"
                  label={t('movementsHistory.orders.shippingGroup', 'Shipping group')}
                  value={filters.shipping_group_number}
                  onChange={(shipping_group_number) => setFilters({ ...filters, shipping_group_number })}
                />

                <InputFilter
                  controlId="filter-tracking-number"
                  label={t('movementsHistory.orders.trackingNumber', 'Tracking number')}
                  value={filters.tracking_number}
                  onChange={(tracking_number) => setFilters({ ...filters, tracking_number })}
                />

                <InputFilter
                  controlId="filter-parcel-number"
                  label={t('movementsHistory.orders.parcel', 'Parcel')}
                  value={filters.parcel_number}
                  onChange={(parcel_number) => setFilters({ ...filters, parcel_number })}
                />

                <InputFilter
                  controlId="filter-item-id"
                  label={t('movementsHistory.orders.item', 'Item')}
                  value={filters.item_id}
                  onChange={(item_id) => setFilters({ ...filters, item_id })}
                />

                <SelectFilter
                  controlId="filter-shipping-method"
                  label={t('movementsHistory.orders.shippingMethod', 'Shipping method')}
                  selected={filters.shipping_method}
                  options={shippingMethods.map(sm => ({ id: sm, label: sm }))}
                  onChange={(shipping_method) => setFilters({ ...filters, shipping_method })}
                />

                <SelectFilter
                  controlId="filter-packaging-type"
                  label={t('movementsHistory.orders.packagingType', 'Type')}
                  options={[{
                    id: 'parcel',
                    label: 'parcel',
                  }, {
                    id: 'no-packaging',
                    label: 'no-packaging',
                  }, {
                    id: 'ecom-prep',
                    label: 'ecom-prep',
                  }]}
                  selected={filters.packaging_type}
                  onChange={(packaging_type) => setFilters({ ...filters, packaging_type })}
                />

                <SelectFilter
                  controlId="filter-action-type"
                  label={t('movementsHistory.orders.action', 'Action')}
                  options={[{
                    id: 'pick',
                    label: t('movementsHistory.orders.actionPick', 'Pick')
                  }, {
                    id: 'cannot-pick-cancel',
                    label: t('movementsHistory.orders.actionCannotPickCancel', 'Cannot Pick Cancel'),
                  }, {
                    id: 'cannot-pick-replace',
                    label: t('movementsHistory.orders.actionCannotPickReplace', 'Cannot Pick Replace')
                  }, {
                    id: 'cancel-picking',
                    label: t('movementsHistory.orders.cancelPicking', 'Cancel picking')
                  }, {
                    id: 'remove-picking',
                    label: t('movementsHistory.orders.removePicking', 'Remove picking')
                  }, {
                    id: 'finalize-picking',
                    label: t('movementsHistory.orders.finalizePicking', 'Finalize picking')
                  }, {
                    id: 'add',
                    label: t('movementsHistory.orders.actionAdd', 'Add'),
                  }, {
                    id: 'give',
                    label: t('movementsHistory.orders.actionGive', 'Give')
                  }, {
                    id: 'delete',
                    label: t('movementsHistory.orders.actionDelete', 'Delete')
                  }, {
                    id: 'move-finish',
                    label: t('movementsHistory.orders.actionMoveFinish', 'Move finish'),
                  }, {
                    id: 'cancel',
                    label: t('movementsHistory.orders.actionCancel', 'Cancel'),
                  }, {
                    id: 'remove',
                    label: t('movementsHistory.orders.actionRemove', 'Remove'),
                  }, {
                    id: 'missing-item',
                    label: t('movementsHistory.orders.actionMissingItem', 'Missing item'),
                  }, {
                    id: 'found-item',
                    label: t('movementsHistory.orders.actionFoundItem', 'Found item'),
                  }, {
                    id: 'unexpected-item',
                    label: t('movementsHistory.orders.actionUnexpectedItem', 'Unexpected item'),
                  }, {
                    id: 'unexpected-order',
                    label: t('movementsHistory.orders.actionUnexpectedOrder', 'Unexpected item'),
                  }, {
                    id: 'problem-resolved',
                    label: t('movementsHistory.orders.actionProblemResolved', 'Problem resolved'),
                  }, {
                    id: 'anomaly-exchange',
                    label: t('movementsHistory.orders.actionAnomalyExchange', 'Anomaly exchange'),
                  }, {
                    id: 'anomaly-exchange-cc1h',
                    label: t('movementsHistory.orders.actionAnomalyExchangeCC1h', 'Anomaly exchange (C&C1h)'),
                  }, {
                    id: 'to-mount',
                    label: t('movementsHistory.orders.toMount', 'To mount'),
                  }, {
                    id: 'mount',
                    label: t('movementsHistory.orders.mount', 'Mount'),
                  }, {
                    id: 'back-to-sale',
                    label: t('movementsHistory.orders.actionBackToSale', 'Back To Sale'),
                  }, {
                    id: 'give-automation',
                    label: t('movementsHistory.orders.giveAutomation', 'Give automation'),
                  }, {
                    id: 'print',
                    label: t('movementsHistory.orders.print', 'Print'),
                  }, {
                    id: 'give-carrier',
                    label: t('movementsHistory.orders.giveCarrier', 'Give to carrier')
                  },
                ]}
                  selected={filters.action_type}
                  onChange={(action_type) => setFilters({ ...filters, action_type })}
                />

                <InputFilter
                  controlId="filter-email"
                  label={t('movementsHistory.orders.email', 'Email')}
                  value={filters.email}
                  onChange={(email) => setFilters({ ...filters, email })}
                />

                <SelectFilter
                  controlId="filter-read-rfid"
                  label={t('movementsHistory.orders.readRFID', 'Read with RFID?')}
                  options={[{
                    id: 'yes',
                    label: t('shared.yes', 'Yes'),
                  }, {
                    id: 'no',
                    label: t('shared.no', 'No'),
                  }]}
                  selected={filters.read_rfid}
                  onChange={(read_rfid) => setFilters({ ...filters, read_rfid })}
                />
              </>
            )}

            <div className="spacer" />

            <div>
              <button
                className="icon-button filter-button filter-button-toggle btn btn-primary btn-sm"
                onClick={() => setVisibility(isFull ? 'partial' : 'full')}
              >
                {isFull ? (
                  t('filters.less', 'Less')
                ) : (
                  t('filters.more', 'More')
                )}
                {isFull ? (
                  <i className="vtmn-icon_chevron_up_v2 right"></i>
                ) : (
                  <i className="vtmn-icon_chevron_down_v2 right"></i>
                )}
              </button>

              <button
                className="icon-button filter-button filter-button-clear btn btn-primary btn-sm"
                onClick={onClear}
              >
                <i className="vtmn-icon_cross"></i>
                <Trans i18nKey="filters.clear">Clear</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;

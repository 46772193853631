import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFromatOptions } from '../../components/print/helpers';
import SimpleSelect from '../../components/widgets/SimpleSelect';
import useRequest from '../../functions/useRequest';
import { getPrinters } from '../../components/print/api';
import { useUserReduxState } from '../../store/user';
import { getNopackLabels, printNopackLabels } from './api';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/widgets/errorMessage';

const useOnPrint = ({ onSuccess }) => {
  const { user, updateTokens } = useUserReduxState();
  const [{ loading: isLabelsFetching, error: fetchingError }, fetchNopackLabels] = useRequest(getNopackLabels, {
    onSuccess: (result) => {
      onSuccess();
      const blobURL = URL.createObjectURL(result);
      window.open(blobURL, '_blank')?.focus();
    }
  });

  const [{ loading: isLabelsPrinting, error: printingError }, doPrintShippingLabels] = useRequest(printNopackLabels, {
    onSuccess,
  });
  const printing = isLabelsFetching || isLabelsPrinting;
  const error = fetchingError || printingError;

  const onPrint = async ({ store, trackingNumber, shippingGroup, format, printer }) => {
    if (!shippingGroup || !store || !printer) return;
    if (printer === 'file') {
      await fetchNopackLabels({
        store,
        format,
        tracking_number: trackingNumber,
        shipping_group: shippingGroup,
        user,
        updateTokens,
      });
    } else {
      await doPrintShippingLabels({
        store,
        format,
        printer,
        tracking_number: trackingNumber,
        shipping_group: shippingGroup,
        user,
        updateTokens,
      })
    }
  };

  return {
    onPrint,
    printing,
    error,
  };
}

const PrinterSummaryDialog = ({
  show,
  trackingNumber,
  shippingGroup,
  onHide,
}) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const { user, updateTokens } = useUserReduxState();
  const [{ loading: isPrintersFetching, data: printers, error: printersError }, fetchPrinters] = useRequest(getPrinters);
  useEffect(() => {
    if (store) {
      fetchPrinters({ store, user, updateTokens });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const { onPrint, printing, error } = useOnPrint({ onSuccess: onHide });

  const options = (printers || []).map(({ id, name }) => ({ id, label: name }));
  const filePrinterLabel = t('selectPrinter.savePdf', 'Save PDF');
  options.push({
    id: 'file',
    label: filePrinterLabel,
  });
  const [selectedPrinterId, setSelectedPrinterId] = useState('file');
  const [selectedFormat, setSelectedFormat] = useState('A4');

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <i className="modal-header-icon text-primary vtmn-icon_printer" />
        <Modal.Title>
          <Trans i18nKey="trackingNumber.printSummarySheet">Print a summary sheet</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {printing && (
          <Loader />
        )}
        {!printing && error && <ErrorMessage error={error} />}
        {!printing && (
          <>
            <SimpleSelect
              label={t('selectPrinter.selectPrinter', 'Select printer')}
              controlId="printer"
              options={options}
              selected={selectedPrinterId}
              onChange={(val) => setSelectedPrinterId(val)}
              loading={isPrintersFetching}
              error={printersError}
            />
            <SimpleSelect
              label={t('selectPrinter.pageFormat', 'Page format')}
              controlId="format"
              options={getFromatOptions(t)}
              selected={selectedFormat}
              onChange={(val) => setSelectedFormat(val)}
            />
          </>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          loading={printing}
          disabled={printing}
          variant="primary"
          onClick={() => {
            onPrint({
              store,
              trackingNumber,
              shippingGroup,
              printer: selectedPrinterId,
              format: selectedFormat,
            });
          }}
        >
          <Trans i18nKey="printLabel.print">Print</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrinterSummaryDialog;

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { withPrintState, saveOptions, withPrintRequest } from './withPrintState';
import { FILETYPE_OPTIONS, getFromatOptions, getOrientationOptions, getPrinter } from './helpers';
import SimpleSelect from '../widgets/SimpleSelect';
import Switch from '../widgets/Switch';
import { printCode } from './api';
import InputNumber from '../widgets/InputNumber';
import RangeInput from '../widgets/RangeInput';
import '../../stylesheets/printLabelModal.css';

const serverUrl = process.env.REACT_APP_SERVERURL;

const getLabelUrl = ({ item, options, getBarcode, getText }) => {
  const {
    width,
    height,
    padding,
    fontsize,
    ratio,
    fit,
    qr,
    horizontal,
    format,
    orientation,
    filetype,
  } = options;
  const barcode = getBarcode(item);
  if (!barcode) {
    return;
  }
  const text = getText(item);
  let params = 'text=' + encodeURIComponent(text);
  if (width) {
    params += `&width=${width}`;
  }
  if (height) {
    params += `&height=${height}`;
  }
  if (padding) {
    params += `&padding=${padding}`;
  }
  if (fontsize) {
    params += `&fontsize=${fontsize}`;
  }
  if (ratio) {
    params += `&ratio=${ratio}`;
  }
  if (format) {
    params += `&format=${format}`;
  }
  if (orientation) {
    params += `&orientation=${orientation}`;
  }
  if (filetype) {
    params += `&filetype=${filetype}`;
  }
  let codetype;
  if (qr) {
    codetype = 'qrcode';
    if (horizontal) {
      params += `&horizontal=${horizontal}`;
    }
  } else {
    codetype = 'barcode';
    if (fit) {
      params += `&fit=${fit}`;
    }
  }

  if (params) {
    params = '?' + params;
  }
  const url = `${serverUrl}/v1/print/${codetype}/${barcode}${params}`;
  return url;
};

const getPrintParams = ({ item, options, getBarcode, getText }) => {
  const {
    width,
    height,
    padding,
    fontsize,
    ratio,
    fit,
    qr,
    horizontal,
    format,
    orientation,
    filetype,
  } = options;

  const barcode = getBarcode(item);
  if (!barcode) {
    return;
  }
  const text = getText(item);

  let printOpts = { text };

  if (width) {
    printOpts.width = Number(width);
  }
  if (height) {
    printOpts.height = Number(height);
  }
  if (padding) {
    printOpts.padding = Number(padding);
  }
  if (fontsize) {
    printOpts.fontsize = Number(fontsize);
  }
  if (ratio) {
    printOpts.ratio = Number(ratio);
  }
  if (format) {
    printOpts.format = format;
  }
  if (orientation) {
    printOpts.orientation = orientation;
  }
  if (filetype) {
    printOpts.filetype = filetype;
  }
  let codetype;
  if (qr) {
    codetype = 'qrcode';
    if (horizontal) {
      printOpts.horizontal = horizontal;
    }
  } else {
    codetype = 'barcode';
    if (fit) {
      printOpts.fit = fit;
    }
  }

  return {
    codetype,
    code: barcode,
    printOpts,
  }
};

const LabelPreview = ({ item, options, getBarcode, getText }) => {
  const url = getLabelUrl({ item, options: { ...options, filetype: 'svg' }, getBarcode, getText });
  return (
    <div className="label-preview-wrapper pt-2">
      <object
        key={getBarcode(item)}
        type="image/svg+xml"
        data={url}
        aria-label="Preview"
        className="label-preview-image"
      >
        <Trans i18nKey="printLabel.preview">Preview</Trans>
      </object>
    </div>
  );
};



export const StatelessPrintLabelModal = ({
  item,
  storageOptsKey,
  getBarcode,
  getText,
  // print state
  width,
  setWidth,
  height,
  setHeight,
  padding,
  setPadding,
  fontsize,
  setFontsize,
  ratio,
  setRatio,
  fit,
  setFit,
  qr,
  setQr,
  horizontal,
  setHorizontal,
  format,
  setFormat,
  filetype,
  setFiletype,
  orientation,
  setOrientation,
  selectedPrinterId,
  setSelectedPrinterId,
  saveLastPrinter,
  printers,
  printersFetching,
  printersError,
  printing,
  onPrint,
  options,
  previewOptions,
  setPreviewOptions,
}) => {
  const { t } = useTranslation();
  const printUrl = getLabelUrl({ item, options, getBarcode, getText });
  const filePrinterLabel = t('selectPrinter.saveFile', 'Save File');
  const printerOptions = (printers || []).map(({ id, name }) => ({ id, label: name }));
  printerOptions.push({
    id: 'file',
    label: filePrinterLabel,
  });
  const isFilePrinting = !selectedPrinterId || selectedPrinterId === 'file';
  const isPdf = filetype === 'pdf';

  return (
    <div className="modal fade" id="printLabel" tabIndex="-1" role="dialog" aria-labelledby="modalPrintLabelTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalPrintLabelTitle">
              <Trans i18nKey="printLabel.title">Printing label</Trans>
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModalPrintLabel">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <InputNumber
              inline
              controlId="label-width"
              label={<Trans i18nKey="printLabel.width">Width (cm):</Trans>}
              min={2}
              max={50}
              step={0.5}
              value={width}
              onChange={setWidth}
            />
            <InputNumber
              inline
              controlId="label-height"
              label={<Trans i18nKey="printLabel.height">Height (cm):</Trans>}
              min={2}
              max={50}
              step={0.5}
              value={height}
              onChange={setHeight}
            />
            <InputNumber
              inline
              controlId="label-padding"
              label={<Trans i18nKey="printLabel.padding">Padding (cm):</Trans>}
              min={0}
              max={5}
              step={0.1}
              value={padding}
              onChange={setPadding}
            />
            <InputNumber
              inline
              controlId="label-fontsize"
              label={<Trans i18nKey="printLabel.fontsize">Fontsize (px):</Trans>}
              min={6}
              max={100}
              step={1}
              value={fontsize}
              onChange={setFontsize}
            />

            <SimpleSelect
              inline
              label={t('selectPrinter.selectPrinter', 'Select printer')}
              controlId="printer"
              options={printerOptions}
              selected={selectedPrinterId}
              onChange={(val) => setSelectedPrinterId(val)}
              loading={printersFetching}
              error={printersError}
            />
            {isFilePrinting && (
              <SimpleSelect
                inline
                label={t('selectPrinter.fileType', 'File type')}
                controlId="filetype"
                options={FILETYPE_OPTIONS}
                selected={filetype}
                onChange={(val) => setFiletype(val)}
              />
            )}
            <SimpleSelect
              inline
              label={t('selectPrinter.pageFormat', 'Page format')}
              controlId="format"
              options={getFromatOptions(t)}
              selected={format}
              onChange={(val) => setFormat(val)}
            />
            {(!isFilePrinting || isPdf) && (
              <SimpleSelect
                inline
                label={t('selectPrinter.orientation', 'Orientation')}
                controlId="orientation"
                options={getOrientationOptions(t)}
                selected={orientation}
                onChange={(val) => setOrientation(val)}
              />
            )}

            <RangeInput
              controlId="label-ratio"
              label={(
                <>
                  <Trans i18nKey="printLabel.barcodeSize">Barcode size:</Trans>
                  {' '}
                  {ratio * 100}
                  {'%'}
                </>
              )}
              min={0}
              max={1}
              step={0.1}
              value={ratio}
              error={(
                <>
                  {(ratio * 100) >= 80 && (
                    <Trans i18nKey="printLabel.warningBarcode80">Be careful: the text might not be visible when the barcode size is bigger than 80%</Trans>
                  )}
                  {(ratio * 100) <= 20 && (
                      <Trans i18nKey="printLabel.warningBarcode20">Be careful: the barcode might not be visible when the barcode size is less than 20%</Trans>
                  )}
                </>
              )}
              onChange={setRatio}
            />

            <Switch
              controlId="label-qr"
              checked={!!qr}
              label={<Trans i18nKey="printLabel.qr">Barcode / QR</Trans>}
              onChange={() => {
                setQr(!qr);
                setPreviewOptions({
                  ...options,
                  qr: !qr,
                });
              }}
            />

            {qr ? (
              <Switch
                controlId="label-horizontal"
                onChange={() => setHorizontal(!horizontal)}
                checked={!!horizontal}
                label={<Trans i18nKey="printLabel.horizontal">Place label to the right</Trans>}
              />
            ) : (
              <Switch
                controlId="label-fit"
                onChange={() => setFit(!fit)}
                checked={!!fit}
                label={<Trans i18nKey="printLabel.fitBarcode">Fit barcode</Trans>}
              />
            )}

            <LabelPreview
              item={item}
              options={previewOptions}
              getBarcode={getBarcode}
              getText={getText}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                saveOptions(storageOptsKey, options);
                saveLastPrinter(getPrinter(printers, selectedPrinterId, filePrinterLabel));
                setPreviewOptions(options)
              }}
            >
              <Trans i18nKey="printLabel.updatePreview">Update preview</Trans>
            </button>
            {isFilePrinting ? (
              <a
                href={printUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
                onClick={() => {
                  saveOptions(storageOptsKey, options);
                  saveLastPrinter(getPrinter(printers, selectedPrinterId, filePrinterLabel));
                  setPreviewOptions(options);
                }}
              >
                <Trans i18nKey="printLabel.print">Print</Trans>
              </a>
            ) : (
              <button
                disabled={printing}
                type="button"
                className="btn btn-primary icon-button"
                onClick={() => {
                  saveOptions(storageOptsKey, options);
                  saveLastPrinter(getPrinter(printers, selectedPrinterId, filePrinterLabel));
                  setPreviewOptions(options);
                  onPrint(getPrintParams({ item, options, getBarcode, getText }));
                }}
              >
                {printing ? (
                  <span className="button-loader">
                    <ClipLoader color="#fff" size={15} loading />
                  </span>
                ) : null}
                <Trans i18nKey="printLabel.print">Print</Trans>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPrintState(withPrintRequest(
  StatelessPrintLabelModal,
  printCode,
  null,
));

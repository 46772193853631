import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { ClipLoader } from 'react-spinners';
import dateFormat from 'dateformat';
import { withTranslation, Trans } from 'react-i18next';
import { Form } from 'react-bootstrap';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import PrintAddressModals from '../../components/print/printAddressModals';
import InlineInputFilter from '../../components/widgets/filters/InlineInputFilter';
import Loader from '../../components/Loader';
import { TableFooter } from '../../components/widgets/DataTable';
import MessageBox from '../../components/widgets/MessageBox';
import Confirm from '../../components/widgets/Confirm';
import WithSidebar from '../../views/WithSidebar';
import ErrorDialog from '../../components/widgets/ErrorDialog';
import EditItemLimitsModal from './EditItemLimitsModal';
import EditOrdersApiModal from './EditOrdersApiModal';
import DeleteBoxSizeModal from './DeleteBoxSizeModal';
import EditBoxSizeModal from './EditBoxSizeModal';
import ManageBoxSizeModal from './ManageBoxSizeModal';

import "../../stylesheets/admin.css";

const serverUrl = process.env.REACT_APP_SERVERURL;

const EMPTY_BOX = { id: null, name: '', height: 0, width: 0, length: 0, weight: 0 };

const formatBox = (box) => ({
  ...box,
  height: box.height && Number(box.height),
  width: box.width && Number(box.width),
  length: box.length && Number(box.length),
  weight: box.weight && Number(box.weight),
});

const getFeatureLabel = (switchConfirmType, t) => {
  const featureLabels = {
    checkProblem: t('adminAddreses.checkProblemLabel', 'Check stock problem'),
    itemStorage: t('adminAddreses.itemStorageLabel', 'Item storage'),
    stockOrders: t('adminAddreses.stockOrdersLabel', 'Stock orders'),
    trackingNumbers: t('adminAddreses.trackingNumbersLabel', 'No-packaging orders'),
    lockers: t('adminAddreses.lockersLabel', 'Lockers'),
    ecomOrders: t('adminAddreses.ecomOrdersLabel', 'Ecom preparation'),
    effitag: t('adminAddreses.effitagLabel', 'Effitag'),
  };
  const label = featureLabels[switchConfirmType];
  return label;
};

class AdminAddresses extends Component {
  constructor(props){
    super(props);
    const { t } = props;
    this.state = {
      selectedAddresses: [],
      addressesList: [],
      lettersList: [],
      boxes: [],
      confirmDelBoxShow: false,
      editBoxSizeShow: false,
      manageBoxSizeShow: false,
      loading: true,
      addressToCreate: "",
      error: "",
      errorTitle: null,
      freeRate: 0,
      file: "",
      fileAddress: null,
      fileCleanup: null,
      checkProblemEnabled: false,
      checkProblemLoading: false,
      uploadStatus: null,
      terminating: false,
      addressToPrint: null,
      showDisabled: false,
      letter: null,
      addressFilter: '',
      stockOrdersEnabled: false,
      stockOrdersLoading: false,
      hasStockOrders: false,
      itemStorageEnabled: false,
      itemStorageLoading: false,
      effitagEnabled: false,
      effitagLoading: false,
      trackingNumbersEnabled: false,
      trackingNumbersLoading: false,
      lockersEnabled: false,
      lockersLoading: false,
      ecomOrdersEnabled: false,
      ecomOrdersLoading: false,
      selfpickupEnabled: false,
      toMountEnabled: false,
      hasItemStock: false,
      editOrdersApiShow: false,
      editItemLimitsShow: false,
      currentBox: EMPTY_BOX,
      addressToDelete: null,
      maxItemVolume: 0,
      maxItemWeight: 0,
      maxItemSize: 0,
      ordersApi: null,
      totals: {
        items: 0,
        pages: 0,
      },
      pagination: {
        page: 0,
        size: 20,
      },
      switchMessageShow: false,
      switchConfirmType: null, // 'checkProblem' | 'itemStorage' | 'stockOrders' | 'trackingNumbers' | 'lockers' | 'ecomOrders'
    };
    this.modalSuccessRef = React.createRef();
    this.modalProgressRef = React.createRef();
    this.modalConfirmDeleteAddressRef = React.createRef();
    this.selectAllRef = React.createRef();
    this.refreshUploadInterval = null;
    this.addressTypes = {
      'address': t('adminAddreses.addressTypes.address', 'Teammate pick-up'),
      'locker': t('adminAddreses.addressTypes.locker', 'Locker'),
      'selfpickup': t('adminAddreses.addressTypes.selfpickup', 'Self pick-up'),
    }
    this.ordersApiTypes = {
      'cube_api': t('adminAddreses.ordersApiTypes.cubeApi', 'Cube'),
      'stores_fulfiller_api': t('adminAddreses.ordersApiTypes.storesFulfillerApi', 'Stores Fulfiller'),
      'one_shop_api': t('adminAddreses.ordersApiTypes.oneShopApi', 'One shop'),
      'hybrid_api': t('adminAddreses.ordersApiTypes.hybridApi', 'Hybrid'),
    }
  }

  componentDidMount(){
    this.initializePage();
    this.getReserveStatus();
    this.getStoreUploadStatus();
    this.getCheckProblemsEnabled();
    this.getBoxesSize();
  }

  componentWillUnmount() {
    if (this.refreshUploadInterval) {
      clearInterval(this.refreshUploadInterval);
    }
  }

  refreshUploadStatus = (uploadId) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/addresses/status/upload/${uploadId}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(uploadStatus => {
      this.setState({ uploadStatus });
      if (!uploadStatus || uploadStatus.status === 'success' || uploadStatus.status === 'failed') {
        if (this.refreshUploadInterval) {
          clearInterval(this.refreshUploadInterval);
        }
      }
      if (uploadStatus && uploadStatus.status === 'success') {
        const { address, item_count, addresses_created, addresses_enabled } = uploadStatus;
        this.hideUploadProgress();
        if (address) {
          this.showSuccess(
            t('adminAddreses.uploadFinishedIn', {
              defaultValue: 'Upload finished for {{count}} items in {{address}}',
              count: item_count,
              address,
            })
          );
        } else {
          this.showSuccess(
            <>
              {t('adminAddreses.uploadFinished', {
                defaultValue: 'Upload finished for {{count}} items',
                count: item_count,
              })}
              <br/>
              {`${t('adminAddreses.createdAddreses', 'Created addresses:')} ${addresses_created}`}
              <br/>
              {`${t('adminAddreses.enabledAddreses', 'Enabled addresses:')} ${addresses_enabled}`}
            </>
          );
        }
        this.initializePage();
      } else if (uploadStatus && uploadStatus.status === 'failed') {
        this.hideUploadProgress();
        const { error_message } = uploadStatus;
        this.showError(error_message || t('shared.defaultError', 'Error occured'));
      } else if (!uploadStatus) {
        this.setState({ terminating: false });
        this.hideUploadProgress();
        this.showError(t('adminAddreses.uploadCanceled', 'Upload was canceled'));
      }
    });
  }

  onUploadStarted = (uploadStatus) => {
    this.setState({ uploadStatus });
    if (uploadStatus && (uploadStatus.status === 'new' || uploadStatus.status === 'inprogress' )) {
      this.showUploadProgress();
      this.refreshUploadInterval = setInterval(() => {
        this.refreshUploadStatus(uploadStatus.id);
      }, 5000);
    }
  }

  getStoreUploadStatus = () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/addresses/status/upload`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(uploadStatus => this.onUploadStarted(uploadStatus));
  }

  onUploadTerminate = () => {
    const { uploadStatus } = this.state;
    if (!uploadStatus) {
      return
    }
    const { store } = this.props.match.params;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({ terminating : true });
    return fetchWithJWT(`${serverUrl}/v1/${store}/addresses/status/upload/${uploadStatus.id}`, {
      method: 'DELETE',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.code !== '200') {
        this.showError(response.text || t('shared.defaultError', 'Error occured'));
        this.setState({ terminating : false });
      }
    })
    .catch(error => {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      this.setState({ terminating : false });
    });
  }

  getCheckProblemsEnabled = () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      checkProblemLoading: true,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/check_store_stock`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(({ enabled }) => {
      this.setState({
        checkProblemEnabled: enabled,
        checkProblemLoading: false,
      });
    });
  }

  setCheckProblems = (enabled) => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      checkProblemLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/check_store_stock`, {
      method: 'PUT',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(() => this.getCheckProblemsEnabled());
  }

  setStockOrdersEnabled = async (enabled) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      stockOrdersLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/stock_orders`, {
      method: 'PATCH',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.text && response.text !== 'OK') {
        const isWarning = response.code === '201';
        const errorTitle = (
          isWarning
            ? t('shared.warning', 'Warning')
            : t('shared.error', 'Error')
        );
        this.showError(response.text, errorTitle);
      }
    })
    .then(this.getReserveStatus)
    .then(() => {
      this.setState({
        stockOrdersLoading: false,
      });
    })
    .catch((e) => {
      console.warn('Error at setStockOrdersEnabled', e);
      this.setState({
        stockOrdersLoading: false,
      });
    });
  }

  setTrackingNumbersEnabled = async (enabled) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      trackingNumbersLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/tracking_numbers`, {
      method: 'PATCH',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.code !== '201') {
        const errorTitle = t('shared.error', 'Error');
        this.showError(response.text, errorTitle);
      }
    })
    .then(this.getReserveStatus)
    .then(() => {
      this.setState({
        trackingNumbersLoading: false,
      });
    })
    .catch((e) => {
      console.warn('Error at setTrackingNumbersEnabled', e);
      this.setState({
        trackingNumbersLoading: false,
      });
    });
  }

  setLockersEnabled = async (enabled) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      lockersLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/lockers`, {
      method: 'PATCH',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.code !== '201') {
        const errorTitle = t('shared.error', 'Error');
        this.showError(response.text, errorTitle);
      }
    })
    .then(this.getReserveStatus)
    .then(() => {
      this.setState({
        lockersLoading: false,
      });
      return this.initializePage();
    })
    .catch((e) => {
      console.warn('Error at setLockersEnabled', e);
      this.setState({
        lockersLoading: false,
      });
    });
  }

  setEcomOrdersEnabled = async (enabled) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      ecomOrdersLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/ecom_orders`, {
      method: 'PATCH',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.code !== '201') {
        const errorTitle = t('shared.error', 'Error');
        this.showError(response.text, errorTitle);
      }
    })
    .then(this.getReserveStatus)
    .then(this.getBoxesSize)
    .then(() => {
      this.setState({
        ecomOrdersLoading: false,
        manageBoxSizeShow: enabled,
      });
    })
    .catch((error) => {
      console.warn('Error at setEcomOrdersEnabled', error);
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      this.setState({
        ecomOrdersLoading: false,
      });
    });
  }

  setEffitagEnabled = async (enabled) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      effitagLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/effitag`, {
      method: 'PATCH',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.text && response.text !== 'OK') {
        const isWarning = response.code === '201';
        const errorTitle = (
          isWarning
            ? t('shared.warning', 'Warning')
            : t('shared.error', 'Error')
        );
        this.showError(response.text, errorTitle);
      }
    })
    .then(this.getReserveStatus)
    .then(() => {
      this.setState({
        effitagLoading: false,
      });
      this.initializePage();
    })
    .catch((e) => {
      console.warn('Error at setEffitagEnabled', e);
      this.setState({
        effitagLoading: false,
      });
    });
  }

  setAddressDelete = async () => {
    const { store } = this.props.match.params;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { addressToDelete } = this.state;
    this.setState({ loading: true });
    return fetchWithJWT(`${serverUrl}/v2/${store}/addresses/${addressToDelete}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "DELETE",
    })
        .then(response => {
          this.initializePage()
        })
        .catch(error => {
          this.setState({ loading: false });
          this.showError(error.message || t('shared.defaultError', 'Error occured'));
          console.warn(`Error while removing address ${addressToDelete}`);
        })
  }

  setItemStorageEnabled = async (enabled) => {
    const { store } = this.props.match.params;
    const { t, user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    this.setState({
      itemStorageLoading: true,
      switchConfirmType: null,
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/item_storage`, {
      method: 'PATCH',
      body: JSON.stringify({ enabled }),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(response => {
      if (response.text && response.text !== 'OK') {
        const isWarning = response.code === '201';
        const errorTitle = (
          isWarning
            ? t('shared.warning', 'Warning')
            : t('shared.error', 'Error')
        );
        this.showError(response.text, errorTitle);
      }
    })
    .then(this.getReserveStatus)
    .then(() => {
      this.setState({
        itemStorageLoading: false,
      });
      this.initializePage();
    })
    .catch((e) => {
      console.warn('Error at setItemStorageEnabled', e);
      this.setState({
        itemStorageLoading: false,
      });
    });
  }

  onSwitchClick = () => {
    if (!this.props.user.isSuperAdmin) {
      this.setState({ switchMessageShow: true });
    }
  }

  handleAddressToCreate = (event) => {
    this.setState({addressToCreate: event.target.value})
  }

  handleSubmitFile = (target, fileAddress) => {
    if (this.state.fileCleanup && this.state.fileAddress !== fileAddress) {
      // we are using same state.file for all file selects
      // so, cleanup file select, on picking file from another one
      this.state.fileCleanup();
    }
    this.setState({
      file : target.files,
      fileAddress,
      fileCleanup: () => target.value = '',
    });
  }

  // -----------------------------------------
  // empty address stock and load a file for address
  // -----------------------------------------
  processFileOneAddress = (file, address) => {
    const { t } = this.props;
    if (file[0] === undefined) {
      this.showError(t('adminAddreses.noFileSelected', 'No file selected'));
      return;
    }

    let myUser = localStorage.getItem("email");
    if (this.props.user.email !== null || this.props.user.email !== undefined){
      myUser = this.props.user.email;
    }
    this.setState({loading: true});
    return this.cleanAddress(address)
    .then(() => {
      const { store } = this.props.match.params;
      const { user, updateTokens } = this.props;
      const { token, refreshToken, tokenExpireDate } = user;
      const url = `${serverUrl}/v1/${store}/addresses/single/upload`;
      const body = new FormData();
      body.append('user', myUser);
      body.append('address', address);
      body.append('file', file[0]);
      return fetchWithJWT(url, {
        method: 'POST',
        body,
        headers: {},
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      })
    })
    .then(result => result.json())
    .then(response => {
      if (!response.code) {
        if (this.state.fileCleanup) {
          this.state.fileCleanup();
        }
        this.onUploadStarted(response);
        this.setState({
          loading : false,
          error: '',
          file: '',
          fileCleanup: null,
        });
      } else {
        this.showError(response.text || t('shared.defaultError', 'Error occured'));
        this.setState({ loading : false });
      }
    })
    .catch(error => {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      this.setState({ loading : false });
    });
  }

  cleanAddress = async (address) => {
    this.setState({ loading : true });
    const { store } = this.props.match.params;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    try {
      const result = await fetchWithJWT(`${serverUrl}/v1/${store}/addresses/${address}`, {
        jwtOpts: {
          token,
          refreshToken,
          tokenExpireDate,
          updateTokens,
        }
      })
      .then(response => response.json());
      await Promise.all(result.stock.map(element => {
        const myBody = {
          item_id : element.item,
          qty : - element.qty
        };
        return fetchWithJWT(`${serverUrl}/v1/${store}/addresses/${address}`, {
          method: "PUT",
          body:JSON.stringify(myBody),
          jwtOpts: {
            token,
            refreshToken,
            tokenExpireDate,
            updateTokens,
          }
        })
      }));
      await Promise.all(result.stock_orders.map(order => {
        const orderCode = order.order_number || order.shipping_group_number;
        const url = `${serverUrl}/v1/${this.props.match.params.store}/stock_orders/${orderCode}/${order.id}`;
        return fetchWithJWT(url, {
          method: 'DELETE',
          jwtOpts: {
            token,
            refreshToken,
            tokenExpireDate,
            updateTokens,
          }
        });
      }));
      this.initializePage();
    } catch (error) {
      this.setState({ loading: false });
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      console.warn(`Error while cleaning address ${address} : ${error}`);
    }
  }

  loadAlleys = async () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const alleys = await fetchWithJWT(`${serverUrl}/v1/${store}/addresses/alleys`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    const lettersList = alleys.map(x => x.letter);
    this.setState({ lettersList });
  };

  loadStoreHasStock = async () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const hasStock = await fetchWithJWT(`${serverUrl}/v1/stores/${store}/has_stock`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    this.setState({
      hasItemStock: hasStock.hasItemStock,
      hasStockOrders: hasStock.hasOrderStock,
    });
  }

  // -----------------------------------------
  // retrieve the addresses list of this store
  // -----------------------------------------
  loadAddreses = async () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { showDisabled, letter, addressFilter, pagination } = this.state;
    const filters = {};
    if (letter) {
      filters.letter = letter;
    }
    if (!showDisabled) {
      filters.disabled = false;
    }
    if (addressFilter) {
      filters.address = addressFilter;
    }
    const queryString = getQueryString({
      filters,
      pagination,
    });
    const { result: addressesList, contentRange } = await fetchWithJWT(`${serverUrl}/v1/${store}/addresses?${queryString}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handlePaginatedApiResponse);
    const { size } = pagination;
    const items = contentRange.max + 1;
    const pages = Math.max(1, Math.ceil(items / size));
    const totals = {
      items,
      pages,
    };
    this.setState({ addressesList, totals, selectedAddresses: [] });
  }

  reloadAddreses = async () => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      await this.loadAddreses();
    } catch (e) {
      this.showError(e.message || t('shared.defaultError', 'Error occured'));
    } finally {
      this.setState({ loading: false });
    }
  }

  loadEmptyRatio = async () => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const result = await fetchWithJWT(`${serverUrl}/v1/${store}/addresses/empty_ratio`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    }).then(handleApiResponse);
    this.calculateFreeRate(result);
  }

  initializePage = async () => {
    const { t } = this.props;
    try {
      await this.loadEmptyRatio();
      await this.loadStoreHasStock();
      await this.loadAlleys();
      await this.loadAddreses();
      this.setState({ loading: false });
    } catch (error) {
      console.warn("Error while retrieving admin infos : " + error);
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      this.setState({ loading: false });
    }
  }

  getReserveStatus = () => {
    const reserve_id = localStorage.getItem("reserve_id");
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/stores/${reserve_id}`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(result => result.json())
    .then(details => {
      const stockOrdersEnabled = details && details.text && details.text.stock_orders_enabled;
      const trackingNumbersEnabled = details && details.text && details.text.tracking_numbers_enabled;
      const lockersEnabled = details && details.text && details.text.lockers_enabled;
      const ecomOrdersEnabled = details && details.text && details.text.ecom_orders_enabled;
      const selfpickupEnabled = details && details.text && details.text.selfpickup_enabled;
      const itemStorageEnabled = details && details.text && details.text.item_storage_enabled;
      const toMountEnabled = details && details.text && details.text.to_mount_enabled;
      const effitagEnabled = details && details.text && details.text.effitag_enabled;
      const maxItemVolume = details && details.text && details.text.max_item_volume;
      const maxItemWeight = details && details.text && details.text.max_item_weight;
      const maxItemSize = details && details.text && details.text.max_item_size;
      const ordersApi = details && details.text && details.text.orders_api;
      this.setState({
        stockOrdersEnabled,
        trackingNumbersEnabled,
        lockersEnabled,
        ecomOrdersEnabled,
        selfpickupEnabled,
        itemStorageEnabled,
        toMountEnabled,
        effitagEnabled,
        maxItemVolume,
        maxItemWeight,
        maxItemSize,
        ordersApi,
      });
    })
    .catch((e) => {
      console.warn('Error at getReserveStatus', e);
    });
  }

  // ---------------------------------------
  // calculate the free rate of this reserve
  // ---------------------------------------
  calculateFreeRate(addressesRatio){
    this.setState ({
      ...this.state,
      freeRate : Math.round( 100 * addressesRatio.empty_count / addressesRatio.address_count )
    });
  }

  // -------------------------------------------------------
  // filter the array with or without the disabled addresses
  // -------------------------------------------------------
  filterDisabled = (showDisabled) => {
    this.setState ({
      showDisabled,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadAddreses);
  }

  // --------------------------
  // filter the array by letter
  // --------------------------
  filterListByLetter(letter) {
    this.setState ({
      letter,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadAddreses);
  }

  filterByAddress = (addressFilter) => {
    this.setState ({
      addressFilter,
      pagination: {
        ...this.state.pagination,
        page: 0,
      }
    }, this.reloadAddreses);
  }

  setPagination = (nextPagination) => {
    const { totals } = this.state;
    const nextTotals = {
      items: totals.items,
      pages: Math.max(1, Math.ceil(totals.items / nextPagination.size)),
    };
    this.setState({
      totals: nextTotals,
      pagination: nextPagination,
    }, this.reloadAddreses);
  }

  // ------------------
  // disable an address
  // ------------------
  disableAddress({ id, address }, targetState){
    this.setState ({loading : true});
    const { store } = this.props.match.params;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    return fetchWithJWT(`${serverUrl}/v2/${store}/addresses/${address}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify({ "disabled" : targetState }),
      method: "PATCH"
    })
    .then(handleApiResponse)
    .then(() => {
      this.initializePage();
      if (targetState) {
        this.setState({ addressToDelete: address });
        this.showConfirmDeleteAddress();
      }
    })
    .catch(error => {
      this.setState({ loading: false });
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      console.warn(error);
    });
  }

  showConfirmDeleteAddress = () => {
    window.$(this.modalConfirmDeleteAddressRef.current).modal('show');
  }

  // -----------------
  // create an address
  // -----------------
  createAddress(addressToCreate){
    this.setState ({loading : true});
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    return fetchWithJWT(`${serverUrl}/v1/${store}/addresses/${addressToCreate}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "POST"
    })
    .then(response => response.json())
    .then(result => {
      if (result.code === "201"){
        this.setState({loading : false});
        this.initializePage();
      } else {
        this.setState({loading : false});
        this.showError(result.text);
      }
    })
    .catch(error => {
        console.warn(error);
    });
  }

  // -----------------
  // chart % of empty addresses
  // -----------------
  drawChart(){
    const { t } = this.props;
    const data = {
      labels: [
        t('adminAddreses.atLeast1', 'At least one item % '),
        t('adminAddreses.empty', 'Empty % '),
      ],
      datasets: [{
        data: [100 - this.state.freeRate, this.state.freeRate],
        backgroundColor: [
        '#ff4d4d',
        '#00cc00'
        ],
        hoverBackgroundColor: [
        '#ff8080',
        '#1aff1a'
        ]
      }]
    };
    return(
      <div className="pie">
        <Pie data={data}
          width={120}
          height={60}
          legend={null}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
    )
  }

  // -----------------
  // empty all addresses from a reserve
  // -----------------
  emptyAllReserve(){
    this.setState({
      loading: true
    });
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/addresses`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "DELETE",
    })
    .then(response => this.initializePage())
  }

  // -----------------
  // uploads csv file with all stocks
  // -----------------
  uploadFileSeveralAddresses = (file) => {
    const { t } = this.props;
    if (file[0] === undefined) {
      this.showError(t('adminAddreses.noFileSelected', 'No file selected'));
      return;
    }

    let myUser = localStorage.getItem("email");
    if (this.props.user.email !== null || this.props.user.email !== undefined){
      myUser = this.props.user.email;
    }
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;

    this.setState({loading: true});
    const url = `${serverUrl}/v1/${store}/addresses/mass/upload`;
    const body = new FormData();
    body.append('user', myUser);
    body.append('file', file[0]);
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      headers: {},
      method: 'POST',
      body,
    })
    .then(result => result.json())
    .then(response => {
      if (this.state.fileCleanup) {
        this.state.fileCleanup();
      }
      if (!response.code) {
        this.onUploadStarted(response);
        this.setState({
          loading : false,
          error: '',
          file: '',
          fileCleanup: null,
        });
      } else {
        this.showError(response.text || t('shared.defaultError', 'Error occured'));
        this.setState({ loading : false });
      }
    })
    .catch(error => {
      if (this.state.fileCleanup) {
        this.state.fileCleanup();
      }
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
      this.setState({ loading : false });
    });
  }

  removePickingLists = () => {
    this.setState ({loading : true});
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/picking_lists`, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(response => response.json())
    .then(response => Promise.all(response.map(element => fetchWithJWT(`${serverUrl}/v1/${store}/picking_lists/${element.id_picking_list}`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "DELETE"
    }))))
    .then(response => {
      this.setState({loading:false})
    })
    .catch(error => {
      console.warn(`Error while removing all picking lists for store ${store}`);
    })
  }

  showError = (error, errorTitle) => {
    this.setState({ error, errorTitle });
  }

  showSuccess = (success) => {
    window.$(this.modalSuccessRef.current).modal('show');
    this.setState({ success });
  }

  showUploadProgress = () => {
    window.$(this.modalProgressRef.current).modal('show');
  }

  hideUploadProgress = () => {
    window.$(this.modalProgressRef.current).modal('hide');
  }

  isUploading = () => {
    const { uploadStatus } = this.state;
    const isUploading = (
      uploadStatus
      && (uploadStatus.status === 'new' || uploadStatus.status === 'inprogress')
    );
    return isUploading;
  }

  isUploadingStock = () => {
    const { uploadStatus } = this.state;
    const isUploading = (
      uploadStatus
      && uploadStatus.address === null
      && (uploadStatus.status === 'new' || uploadStatus.status === 'inprogress')
    );
    return isUploading;
  }

  isUploadingAddress = (address) => {
    const { uploadStatus } = this.state;
    const isUploading = (
      uploadStatus
      && uploadStatus.address === address
      && (uploadStatus.status === 'new' || uploadStatus.status === 'inprogress')
    );
    return isUploading;
  }

  renderUploadProgress() {
    const { uploadStatus } = this.state;
    if (!uploadStatus) {
      return;
    }

    const {
      progress_total,
      progress_processed,
      address,
      item_count,
      change_date,
    } = uploadStatus;
    let progress = (progress_processed || 0) / (progress_total || 1);
    if (progress > 1) {
      progress = 1;
    }

    return (
      <div>
        {address ? (
          <div>
            <Trans i18nKey="adminAddreses.uploadingAddress">Uploading address</Trans>
            {': '}
            {address}
          </div>
        ) : (
          <div>
            <Trans i18nKey="adminAddreses.uploadingStoreCom">Uploading store stock</Trans>
          </div>
        )}
        <div>
          <Trans i18nKey="adminAddreses.numLines">Number of lines</Trans>
          {': '}
          {item_count}
        </div>
        <div>
          <Trans i18nKey="adminAddreses.lastUpdate">Last update</Trans>
          {': '}
          {change_date && dateFormat(new Date(change_date), "dddd, mmmm dS, yyyy, h:MM:ss TT")}
        </div>

        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress * 100}%`, backgroundColor: '#0082C3' }}
            aria-valuenow={progress * 100}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {`${(progress * 100).toFixed(0)} %`}
          </div>
        </div>
      </div>
    )
  }

  renderUploadProgressDialog() {
    return (
      <div
        key="progress-modal" className="modal fade" id="modal-progress" tabIndex="-1"
        role="dialog" aria-labelledby="modalProgressTitle" aria-hidden="true"
        ref={this.modalProgressRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <i className="modal-header-icon text-primary vtmn-icon_notif_information"></i>
              <h5 className="modal-title" id="modalProgressTitle">
                <Trans i18nKey="adminAddreses.uploadInProgress">Upload in progress</Trans>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal-progress"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.renderUploadProgress()}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-danger icon-button"
                onClick={this.onUploadTerminate}
                disabled={this.state.terminating}
              >
                {this.state.terminating ? (
                  <span className="button-loader">
                    <ClipLoader color="#fff" size={15} loading />
                  </span>
                ) : null}
                <Trans i18nKey="adminAddreses.terminateUpload">Terminate upload</Trans>
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                {' '}
                <Trans i18nKey="shared.ok">OK</Trans>
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSuccessDialog() {
    const { success } = this.state;
    return (
      <div
        key="success-modal" className="modal fade" id="modal-success" tabIndex="-1"
        role="dialog" aria-labelledby="modalSuccessTitle" aria-hidden="true"
        ref={this.modalSuccessRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <i className="modal-header-icon text-success vtmn-icon_valid_rounded"></i>
              <h5 className="modal-title" id="modalSuccessTitle">
                <Trans i18nKey="shared.success">Success</Trans>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-success">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {success}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-dismiss="modal">
                {' '}
                <Trans i18nKey="shared.ok">OK</Trans>
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderConfirmDeleteAddressDialog() {
    return this.renderConfirmDeleteDialog({
      code: 'address',
      message: (
        <>
          <p>
            <Trans i18nKey="adminAddreses.confirmNoHistory">This address is disabled, you can delete this address.</Trans>
            <Trans i18nKey="adminAddreses.confirmDeleteAddress">Are you sure, you want to delete this address?</Trans>
          </p>
        </>
      ),
      onConfirm: () => this.setAddressDelete(),
      ref: this.modalConfirmDeleteAddressRef,
    });
  }

  renderConfirmDeleteDialog({ message, code, onConfirm, ref }) {
    return (
        <div
            key={`confirm-delete-modal-${code}`} className="modal fade" id="modal-confirm-delete-address" tabIndex="-1"
            role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="true"
            ref={ref}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <i className="modal-header-icon text-danger vtmn-icon_notif_alert"></i>
                <h5 className="modal-title" id="modalConfirmTitle">
                  <Trans i18nKey="shared.warning">Warning</Trans>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-confirm">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {message}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" data-dismiss="modal">
                  {' '}
                  <Trans i18nKey="shared.cancel">Cancel</Trans>
                  {' '}
                </button>
                <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={onConfirm}
                >
                  {' '}
                  <Trans i18nKey="shared.confirm">Confirm</Trans>
                  {' '}
                </button>
              </div>
            </div>
          </div>
        </div>
    );
  }

  renderErrorDialog() {
    const { error, errorTitle } = this.state;
    return (
      <ErrorDialog
        error={error ? { message: error } : null}
        title={errorTitle}
        setError={() => this.setState({ error: null, errorTitle: null })}
      />
    );
  }

  renderCheckPromlemsSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { checkProblemEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.checkProblems', 'Alert if stores.com stock < Product Locator');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="problemEnabledSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'checkProblem' })}
            checked={checkProblemEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="problemEnabledSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderStockOrdersSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { stockOrdersEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.enableOrders', 'Enable stock orders');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="stockOrdersSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'stockOrders' })}
            checked={stockOrdersEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="stockOrdersSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderTrackingNumbersSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { trackingNumbersEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.enableNoPackaging', 'Enable no-packaging orders');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="trackingNumbersSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'trackingNumbers' })}
            checked={trackingNumbersEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="trackingNumbersSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderOrdersApiSwitch = () => {
    const { t, user } = this.props;
    if (!user.isSuperAdmin) return null;
    const { ordersApi } = this.state;
    return (
      <div className="admin-bar-item">
        <span className="admin-bar-item-title">{`${t('adminAddreses.ordersApi', 'Orders API')}:`}</span>
        <strong className="admin-bar-item-title">
          <mark>{(this.ordersApiTypes[ordersApi] || '-').toUpperCase()}</mark>
        </strong>
        <button
          className="btn btn-sm btn-danger icon-button"
          title="edit item limits"
          onClick={() => { this.setState({ editOrdersApiShow: true }) }}
        >
          <i className="vtmn-icon_edit"></i>
          {t('shared.edit', 'Edit')}
        </button>
      </div>
    );
  }

  renderLockersSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { lockersEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.enableLockers', 'Enable lockers');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="lockersSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'lockers' })}
            checked={lockersEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="lockersSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderEcomOrdersSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { ecomOrdersEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.enableEcomPrep', 'Enable ecom preparation');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="ecomOrdersSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'ecomOrders' })}
            checked={ecomOrdersEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="ecomOrdersSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderItemStorageSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { itemStorageEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.enableItems', 'Enable item storage');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="itemStorageSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'itemStorage' })}
            checked={itemStorageEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="itemStorageSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderEffiTagSwitch = () => {
    const { t, user } = this.props;
    const { isSuperAdmin } = user;
    const { effitagEnabled } = this.state;
    let switchClassName = 'switch switch-sm';
    if (!isSuperAdmin) {
      switchClassName += ' switch-disabled';
    }
    const optionMessage = t('adminAddreses.enableEffitag', 'Enable Effitag');
    return (
      <div className="row justify-content-center align-items-center mt-2" onClick={this.onSwitchClick}>
        <label className={switchClassName}>
          <input
            id="effitagSwitch"
            type="checkbox"
            onChange={() => this.setState({ switchConfirmType: 'effitag' })}
            checked={effitagEnabled}
            disabled={!isSuperAdmin}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="effitagSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
        {optionMessage}
        </label>
        {!isSuperAdmin && <i className="vtmn-icon_notif_help help-tooltip-icon text-primary" />}
      </div>
    );
  }

  renderShowDisabledSwitch = () => {
    const { showDisabled } = this.state;
    return (
      <div className="row justify-content-center">
        <label className="switch switch-sm">
          <input
            id="showDisabledSwitch"
            type="checkbox"
            onChange={() => this.filterDisabled(!showDisabled)}
            checked={showDisabled}
          />
          <span className="slider round"></span>
        </label>
        <label
          className="text"
          htmlFor="showDisabledSwitch"
          style={{ fontSize: '0.75rem', maxWidth: '130px', padding: '0', flex: '1 1 0px' }}
        >
          <Trans i18nKey="adminAddreses.showDisabled">Show disabled</Trans>
        </label>
      </div>
    );
  }

  updateSeleclaAllState = () => {
    const {
      selectedAddresses,
      addressesList,
    } = this.state;
    if (this.selectAllRef && this.selectAllRef.current) {
      const anySelected = selectedAddresses.length > 0;
      const allSelected = selectedAddresses.length === addressesList.length;
      if (allSelected) {
        this.selectAllRef.current.checked = true;
        this.selectAllRef.current.indeterminate = false;
      } else if (anySelected) {
        this.selectAllRef.current.checked = false;
        this.selectAllRef.current.indeterminate = true;
      } else {
        this.selectAllRef.current.checked = false;
        this.selectAllRef.current.indeterminate = false;
      }
    }
  }

  onSelectAllChange = () => {
    const {
      selectedAddresses,
      addressesList,
    } = this.state;
    const allSelected = selectedAddresses.length === addressesList.length;
    if (allSelected) {
      this.setState({ selectedAddresses: [] });
    } else {
      this.setState({ selectedAddresses: addressesList });
    }
  }

  saveItemLimits = (limits) => {
    const { maxItemSize, maxItemWeight, maxItemVolume } = limits;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { store } = this.props.match.params;
    this.setState({
      editItemLimitsShow: null,
      loading: true
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/item_limits`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "PATCH",
      body: JSON.stringify({
        max_item_size: maxItemSize || 0,
        max_item_weight: maxItemWeight || 0,
        max_item_volume: maxItemVolume || 0,
      })
    })
    .then(handleApiResponse)
    .then(() => this.setState({ loading: false }))
    .catch(error => {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
    })
    .then(this.getReserveStatus);
  }

  saveOrdersApi = (ordersApi) => {
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const { store } = this.props.match.params;
    this.setState({
      editOrdersApiShow: false,
      loading: true
    });
    return fetchWithJWT(`${serverUrl}/v1/${store}/settings/orders_api`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "PATCH",
      body: JSON.stringify({
        orders_api: ordersApi
      })
    })
    .then(handleApiResponse)
    .then(() => this.setState({ loading: false }))
    .catch(error => {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
    })
    .then(this.getReserveStatus);
  }

  getBoxesSize = () => {
    const { store } = this.props.match.params;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${serverUrl}/v1/${store}/boxes/`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .then(boxes => {
      this.setState({ boxes });
    })
    .catch(error => {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
    });
  }

  doUpdateBox = (box) => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/boxes/`, {
      method: 'PATCH',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      body: JSON.stringify(box)
    }).then(handleApiResponse);
  }

  doCreateBox = (box) => {
    const { store } = this.props.match.params;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/boxes/`,{
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: "POST",
      body: JSON.stringify({
        name: box.name,
        height: box.height,
        width: box.width,
        length: box.length,
        weight: box.weight,
      })
    }).then(handleApiResponse);
  }

  onEditBoxConfirm = async () => {
    const { t } = this.props;
    try {
      if (this.state.currentBox.id) {
        await this.doUpdateBox(formatBox(this.state.currentBox));
      } else {
        await this.doCreateBox(formatBox(this.state.currentBox));
      }
      this.setState({
        currentBox: EMPTY_BOX,
        editBoxSizeShow: false,
      });
      await this.getBoxesSize();
    } catch (error) {
      this.showError(error.message || t('shared.defaultError', 'Error occured'));
    }
  }

  onDeleteBoxConfirmed = () => {
    const { store } = this.props.match.params;
    const { user, updateTokens, t } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    return fetchWithJWT(`${serverUrl}/v1/${store}/boxes/${this.state.currentBox.id}`, {
      method:'DELETE',
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    }).then(handleApiResponse)
      .then(() => {
        this.setState({
          currentBox: EMPTY_BOX,
          confirmDelBoxShow: false,
        });
        return this.getBoxesSize();
      })
      .catch(error => {
        this.showError(error.message || t('shared.defaultError', 'Error occured'));
        console.warn(`Error when deleting box : ${error}`);
      })
  }

  renderAddressesTable() {
    const { t } = this.props;
    const {
      loading,
      addressesList,
      pagination,
      totals,
      lockersEnabled,
      selfpickupEnabled,
      ecomOrdersEnabled,
    } = this.state;
    const addressTypeEnabled = lockersEnabled || selfpickupEnabled || ecomOrdersEnabled;

    return (
      <>
        { loading && <Loader /> }
        { !loading && (
          <table className="table table-hover text-center">
            <thead>
              <tr>
                <th>
                  <Form.Check
                    ref={this.selectAllRef}
                    type="checkbox"
                    onChange={this.onSelectAllChange}
                  />
                </th>
                <th width={34}>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    data-toggle="modal"
                    data-target="#printMultiAddresses"
                    disabled={loading}
                    title={t('adminAddreses.printAll', 'Print all')}
                  >
                    <i className="vtmn-icon_printer"></i>
                  </button>
                </th>
                <th>
                  <Trans i18nKey="adminAddreses.addresses">Addresses</Trans>
                </th>
                <th>
                  <Trans i18nKey="adminAddreses.busy">Busy</Trans>
                </th>
                <th>
                  <Trans i18nKey="adminAddreses.actions">Actions</Trans>
                </th>
                {addressTypeEnabled && (
                  <th>
                    <Trans i18nKey="adminAddreses.addressType">Type</Trans>
                  </th>
                )}
                <th>
                  <Trans i18nKey="adminAddreses.uploadStock">Upload stock</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {addressesList.map((address) => this.insertRow(address))}
            </tbody>
          </table>
        )}
        <TableFooter
          pagination={pagination}
          totals={totals}
          setPagination={this.setPagination}
        />
      </>
    );
  }

  onSwitchConfirmHandler = () => {
    const {
      switchConfirmType,
      checkProblemEnabled,
      itemStorageEnabled,
      stockOrdersEnabled,
      trackingNumbersEnabled,
      lockersEnabled,
      ecomOrdersEnabled,
      effitagEnabled,
    } = this.state;
    if (switchConfirmType === 'checkProblem') {
      this.setCheckProblems(!checkProblemEnabled);
    } else if (switchConfirmType === 'itemStorage') {
      this.setItemStorageEnabled(!itemStorageEnabled);
    } else if (switchConfirmType === 'stockOrders') {
      this.setStockOrdersEnabled(!stockOrdersEnabled);
    } else if (switchConfirmType === 'trackingNumbers') {
      this.setTrackingNumbersEnabled(!trackingNumbersEnabled);
    } else if (switchConfirmType === 'lockers') {
      this.setLockersEnabled(!lockersEnabled);
    } else if (switchConfirmType === 'ecomOrders') {
      this.setEcomOrdersEnabled(!ecomOrdersEnabled);
    } else if (switchConfirmType === 'effitag') {
      this.setEffitagEnabled(!effitagEnabled);
    }
  }

  render() {
    const { t } = this.props;
    const {
      letter,
      showDisabled,
      addressFilter,
      selectedAddresses,
      editItemLimitsShow,
      editOrdersApiShow,
      maxItemVolume,
      maxItemWeight,
      maxItemSize,
      ecomOrdersEnabled,
      boxes,
      ordersApi,
      switchConfirmType,
      hasItemStock,
      hasStockOrders,
      pagination,
      addressToPrint,
    } = this.state;
    const switchConfirmValue = switchConfirmType ? this.state[`${switchConfirmType}Enabled`] : null;
    const needItemStockConfirm = switchConfirmType === 'itemStorage' && switchConfirmValue && hasItemStock;
    const needStockOrdersConfirm = switchConfirmType === 'stockOrders' && switchConfirmValue && hasStockOrders;

    const isUploadingStock = this.isUploadingStock();
    const loading = this.state.loading || this.isUploading();
    const readonlyBoxes = !ordersApi || ordersApi === 'stores_fulfiller_api';
    const printPagination = selectedAddresses?.length
      ? {
          ...pagination,
          page: 0,
        }
      : pagination;
    const printFilters = {
      letter,
      address: addressFilter,
      printDisabled: showDisabled,
      addresses: selectedAddresses && selectedAddresses.length ? selectedAddresses.map(x => x.id) : undefined,
    };

    return (
      <WithSidebar
        user={this.props.user}
        updateTokens={this.props.updateTokens}
        logOut={this.props.logOut}
        stockOrdersEnabled={this.state.stockOrdersEnabled}
        itemStorageEnabled={this.state.itemStorageEnabled}
        trackingNumbersEnabled={this.state.trackingNumbersEnabled}
        ecomOrdersEnabled={this.state.ecomOrdersEnabled}
      >
        <div className = "admin-addresses jumbotron container full-width-container">
          <div>
            <h2 className="text-center">
              <Trans i18nKey="adminAddreses.adminAddreses">Admin addresses</Trans>
            </h2>
            <div className="d-flex flex-sm-row flex-column justify-content-between align-items-start pb-2">
              <div className="d-flex flex-column">
                <div>
                  {this.renderCheckPromlemsSwitch()}
                </div>
                <div>
                  {this.renderStockOrdersSwitch()}
                </div>
                <div>
                  {this.renderTrackingNumbersSwitch()}
                </div>
              </div>
              <div className="d-flex flex-column admin-chart-column">
                {this.drawChart()}
                <h6 className="text-center">
                  <em>
                    <Trans i18nKey="adminAddreses.emptyAddresses">Empty addresses</Trans>
                    {' : '}
                    {this.state.freeRate}
                    {' %'}
                    </em>
                </h6>
              </div>
              <div className="d-flex flex-column">
                <div>
                  {this.renderLockersSwitch()}
                </div>
                <div>
                  {this.renderEcomOrdersSwitch()}
                </div>
                <div>
                  {this.renderItemStorageSwitch()}
                </div>
                <div>
                  {this.renderEffiTagSwitch()}
                </div>
              </div>
            </div>
            <div className="admin-bar">
              {this.renderOrdersApiSwitch()}
              <div className="admin-bar-item">
                <span className="admin-bar-item-title">
                  <Trans i18nKey="adminAddreses.voluminousLimits">Voluminous limits</Trans>
                  {":"}
                </span>
                <button
                  className="btn btn-sm btn-danger icon-button"
                  title="edit item limits"
                  disabled={loading}
                  onClick={() => { this.setState({ editItemLimitsShow: true }) }}
                >
                  <i className="vtmn-icon_edit"></i>
                  {t('shared.edit', 'Edit')}
                </button>
              </div>
              <div className="admin-bar-item">
                <span className="admin-bar-item-title">
                  <Trans i18nKey="adminAddreses.pickingLists">Picking lists:</Trans>
                </span>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  data-toggle="modal"
                  data-target="#removePickingListsModal"
                  disabled={loading}
                >
                  <Trans i18nKey="adminAddreses.deletePickingLists">Delete all picking lists</Trans>
                </button>
              </div>
              <div className="admin-bar-item">
                <span className="admin-bar-item-title">
                  <Trans i18nKey="adminAddreses.stock">Stock:</Trans>
                </span>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  data-toggle="modal"
                  data-target="#emptyAllStockModal"
                  disabled={loading}
                >
                  <Trans i18nKey="adminAddreses.emptyAllStock">Empty all stock</Trans>
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger icon-button"
                  data-toggle="modal"
                  data-target="#initializeStockModal"
                  disabled = {loading}
                >
                  {isUploadingStock ? (
                    <span className="button-loader">
                      <ClipLoader color="#fff" size={15} loading />
                    </span>
                  ) : null}
                  <Trans i18nKey="adminAddreses.addStock">Add stock</Trans>
                </button>
                {isUploadingStock ? (
                  <button
                    className="btn btn-sm btn-danger"
                    title={t('adminAddreses.showProgress', 'show progress')}
                    onClick={this.showUploadProgress}
                  >
                    <i className="vtmn-icon_eye_on" />
                  </button>
                ) : null}
              </div>
              {ecomOrdersEnabled && (
                <div className="admin-bar-item">
                  <span className="admin-bar-item-title">
                    <Trans i18nKey="adminAddreses.boxSizes">Box sizes:</Trans>
                  </span>
                  <button
                    className="btn btn-sm btn-danger"
                    disabled={loading}
                    onClick={() => this.setState({ manageBoxSizeShow: true })}
                  >
                    <Trans i18nKey="adminAddreses.manageBoxSize">Manage boxes size</Trans>
                  </button>
                </div>
              )}
            </div>

            <nav className="navbar navbar-light bg-light">
              <button
                type="button"
                className="btn blueButton btn-sm"
                data-toggle="modal"
                data-target="#newAddressModal"
                disabled={loading}
              >
                <Trans i18nKey="adminAddreses.newAddress">New address</Trans>
              </button>
              <InlineInputFilter
                placeholder={t('filters.itemFilter.enterAddress', 'Enter an address...')}
                onChange={(val) => this.filterByAddress(val)}
              />
              {this.renderShowDisabledSwitch() }
              <button
                type="button"
                className={`btn aisleLetter btn-sm ${!this.state.letter ? 'active' : ''}`}
                onClick={ event => this.filterListByLetter(null) }
              >
                All
              </button>
              {this.state.lettersList.map( (letter) => this.insertLetter(letter))}
            </nav>

            {this.renderAddressesTable()}

            <div  className="modal fade" id="emptyAllStockModal" tabIndex="-1" role="dialog" aria-labelledby="emptyAllStockModal" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="emptyAllStockModalTitle">
                      <Trans i18nKey="adminAddreses.empltyAllStockTitle">Empty all stock from the full reserve</Trans>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      <Trans i18nKey="adminAddreses.removeNoRollback">
                        Note that this will remove all stock in the reserve. No rollback.
                      </Trans>
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">
                      {' '}
                      <Trans i18nKey="shared.cancel">Cancel</Trans>
                      {' '}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={() => this.emptyAllReserve()}
                    >
                      <Trans i18nKey="adminAddreses.empltyAll">Empty all</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div  className="modal fade" id="initializeStockModal" tabIndex="-1" role="dialog" aria-labelledby="initializeStockModal" aria-hidden="true">
              <div className="modal-dialog modal-95" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="initializeStockModalTitle">
                      <Trans i18nKey="adminAddreses.addStockTitle">Add stock for the full reserve</Trans>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      <Trans i18nKey="adminAddreses.addNoRollback">
                        Note that this will add stock to your reserve. No cleaning before. Be careful: no rollback is possible.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="adminAddreses.addExampleFile">Desired example file can be found</Trans>
                      {' '}
                      <a href="https://docs.google.com/spreadsheets/d/1au1KRLV79JeChM97ssLMt6dMjXxqOWdX4FHFeThhJAU" target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="adminAddreses.here">here</Trans>
                      </a>
                    </p>
                    <div className="input-group-prepend justify-content-center">
                      <input type="file" accept=".csv" onChange={e => this.handleSubmitFile(e.target, null)} className="input-group-text" />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">
                      {' '}
                      <Trans i18nKey="shared.cancel">Cancel</Trans>
                      {' '}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={() => this.uploadFileSeveralAddresses(this.state.file, this.props.match.params.store)}
                      disabled={!this.state.file || this.state.fileAddress !== null}
                    >
                      <Trans i18nKey="adminAddreses.addStock">Add stock</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div  className="modal fade" id="newAddressModal" tabIndex="-1" role="dialog" aria-labelledby="newAddressModal" aria-hidden="true">
              <div className="modal-dialog modal-95" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="newAddressModalTitle">
                      <Trans i18nKey="adminAddreses.createAddressTitle">Create address</Trans>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <Trans i18nKey="adminAddreses.enterAddress">Enter your address</Trans>
                        </span>
                      </div>
                      <input type="text" className="form-control m-0" onChange={this.handleAddressToCreate} />
                    </div>
                    <div><span id="error">{ this.state.error }</span></div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">
                      {' '}
                      <Trans i18nKey="shared.cancel">Cancel</Trans>
                      {' '}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={() => this.createAddress(this.state.addressToCreate)}
                    >
                      <Trans i18nKey="shared.create">Create</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <ManageBoxSizeModal
              readonly={readonlyBoxes}
              show={this.state.manageBoxSizeShow}
              onHide={() => this.setState({
                currentBox: EMPTY_BOX,
                manageBoxSizeShow: false,
              })}
              onEdit={(box) => this.setState({ currentBox: box, editBoxSizeShow: true })}
              onDelete={(box) => this.setState({ currentBox: box, confirmDelBoxShow: true })}
              onNewBox={() => this.setState({
                currentBox: EMPTY_BOX,
                editBoxSizeShow: true,
              })}
              boxes={boxes}
            />

            <DeleteBoxSizeModal
              show={this.state.confirmDelBoxShow}
              onHide={() => this.setState({ confirmDelBoxShow: false })}
              onConfirm={this.onDeleteBoxConfirmed}
            />

            <EditBoxSizeModal
              show={this.state.editBoxSizeShow}
              onHide={() => this.setState({ editBoxSizeShow: false })}
              box={this.state.currentBox}
              updateBox={(changes) => this.setState({
                currentBox: {
                  ...this.state.currentBox,
                  ...changes,
                }
              })}
              onConfirm={this.onEditBoxConfirm}
            />

            <div  className="modal fade" id="removePickingListsModal" tabIndex="-1" role="dialog" aria-labelledby="removePickingListsModal" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      <Trans i18nKey="adminAddreses.emptyPickingListsTitle">Empty all pickings lists from your store</Trans>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      <Trans i18nKey="adminAddreses.removeListsNoRollback">
                        Note that this will remove only the picking lists. Not the stock. No rollback.
                      </Trans>
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">
                      {' '}
                      <Trans i18nKey="shared.cancel">Cancel</Trans>
                      {' '}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={() => this.removePickingLists()}
                    >
                      <Trans i18nKey="adminAddreses.removeAll">Remove all</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <EditItemLimitsModal
              show={editItemLimitsShow}
              limits={{
                maxItemVolume,
                maxItemWeight,
                maxItemSize,
              }}
              onHide={() => this.setState({ editItemLimitsShow: false })}
              onSave={(limits) => this.saveItemLimits(limits)}
            />
            <EditOrdersApiModal
              show={editOrdersApiShow}
              ordersApi={ordersApi}
              ordersApiTypes={this.ordersApiTypes}
              onHide={() => this.setState({ editOrdersApiShow: false })}
              onSave={(x) => this.saveOrdersApi(x)}
            />
            {this.renderUploadProgressDialog()}
            {this.renderSuccessDialog()}
            {this.renderErrorDialog()}
            {this.renderConfirmDeleteAddressDialog()}

            <PrintAddressModals
              storageOptsKey="printAddressLabelOptions"
              item={addressToPrint}
              filters={printFilters}
              pagination={printPagination}
            />
            <MessageBox
              show={this.state.switchMessageShow}
              onHide={() => this.setState({ switchMessageShow: false })}
              title={t('adminAddreses.switchNotAllowed', 'Change not allowed')}
              body={
                <Trans
                  i18nKey="adminAddreses.contactPLtoSwitch"
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  components={{ a: <a href="mailto:product_locator@decathlon.net" /> }}
                  defaults="Contact <a>product_locator@decathlon.net</a> if you want to enable/disable a module and please specify the reason"
                />
              }
            />
            <Confirm
              show={!!switchConfirmType}
              onHide={() => this.setState({ switchConfirmType: null })}
              onConfirm={this.onSwitchConfirmHandler}
              important={needItemStockConfirm || needStockOrdersConfirm}
              body={
                <>
                  <p>
                    {switchConfirmValue
                      ? t('adminAddreses.confirmDisable', {
                        defaultValue: 'Are you sure you want to disable {{confirmType}}?',
                        confirmType: getFeatureLabel(switchConfirmType, t),
                      })
                      : t('adminAddreses.confirmEnable', {
                        defaultValue: 'Are you sure you want to enable {{confirmType}}?',
                        confirmType: getFeatureLabel(switchConfirmType, t),
                      })}
                  </p>
                  {needItemStockConfirm && (
                    <p>
                      <Trans i18nKey="adminAddreses.confirmItemsInStock">You have some items in stock, please check item report.</Trans>
                    </p>
                  )}
                  {needStockOrdersConfirm && (
                    <p>
                      <Trans i18nKey="adminAddreses.confirmOrdersInStock">You have some orders in stock, please check stock report.</Trans>
                    </p>
                  )}
                </>
              }
            />
          </div>
        </div>
      </WithSidebar>
    )
  }

  insertRow(address) {
    const { t } = this.props;
    const {
      selectedAddresses,
      lockersEnabled,
      selfpickupEnabled,
      ecomOrdersEnabled,
      loading: stateLoading,
      file,
      fileAddress,
    } = this.state;
    const isUploading = this.isUploading();
    const isUploadingAddress = this.isUploadingAddress(address.address);
    const loading = stateLoading || isUploading;
    const replaceDisabled = loading || isUploadingAddress || !file || fileAddress !== address.id;
    const isEmpty = address.qty_ref === "0" && address.stock_orders_count === "0";
    const isBusy = address.stock_total !== "0" || address.stock_orders_count !== "0";
    const isSelected = !!selectedAddresses.find(x => x.id === address.id);
    const addressTypeEnabled = lockersEnabled || selfpickupEnabled || ecomOrdersEnabled;
    return (
      <tr key={address.id} >
        <td className="align-middle pl-0 pr-0">
          <Form.Check
            type="checkbox"
            checked={isSelected}
            onChange={() => {
              let nextSelectedAddresses = [];
              if (isSelected) {
                nextSelectedAddresses = selectedAddresses.filter(x => x.id !== address.id);
              } else {
                nextSelectedAddresses = [
                  ...selectedAddresses,
                  address,
                ];
              }
              this.setState({ selectedAddresses: nextSelectedAddresses }, this.updateSeleclaAllState);
            }}
          />
        </td>
        <td className="align-middle pl-0 pr-0">
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-toggle="modal"
            data-target="#printLabel"
            onClick={() => {
              this.setState({
                addressToPrint: address.address
              });
            }}
          >
            <i className="vtmn-icon_printer"></i>
          </button>
        </td>
        <td className="align-middle">
          {address.address}
          {address.disabled && !isEmpty && (
            <>
              <br/>
              <span className="text-danger small">
                <em>
                  <Trans i18nKey="adminAddreses.disabledAddressHasStock">Disabled address has stock</Trans>
                </em>
              </span>
            </>
          )}
        </td>
        <td className="align-middle">{ isBusy ? <i className="vtmn-icon_valid"></i> : null }</td>
        <td className="align-middle">
          {!isEmpty ? (
            <button
              className="btn btn-sm btn-danger"
              title={t('adminAddreses.emptyAddress', 'empty this address')}
              onClick={event => this.cleanAddress(address.address)}
              disabled = {loading || address.disabled}
            >
              <i className="vtmn-icon_delete vtmn-icon-22px"></i>
            </button>
          ) : null }

          {isEmpty && !address.disabled && address.address !== 'Lockers' ? (
            <button
              className="btn btn-sm btn-danger"
              title={t('adminAddreses.disableAddress', 'disable this address')}
              disabled={loading}
              onClick={event => this.disableAddress(address, true)}
            >
              <img className="img-icon" alt="lock" src="/icons/padlock_close_v2.svg" />
            </button>
          ): null }

          { address.disabled ? (
            <button
              className="btn btn-sm btn-warning"
              title={t('adminAddreses.restoreAddress', 'restore this address')}
              disabled={loading}
              onClick={event => this.disableAddress(address, false)}
            >
              <img className="img-icon" alt="lock" src="/icons/padlock_open_v2.svg" />
            </button>
          ) : null }
        </td>
        {addressTypeEnabled && (
          <td className="align-middle">
            {this.addressTypes[address.address_type]}
          </td>
        )}
        <td>
          {!address.disabled && address.address !== 'Lockers' && (
            <div className="input-group justify-content-center">
              <input type="file" accept=".csv" onChange={e => this.handleSubmitFile(e.target, address.id)} className="input-group-text" />
              <button onClick={() => this.processFileOneAddress(file, address.address, this.props.match.params.store)}
                className="btn btn-success icon-button"
                disabled = {replaceDisabled}
              >
                {isUploadingAddress ? (
                  <span className="button-loader">
                    <ClipLoader color="#fff" size={15} loading />
                  </span>
                ) : null}
                <Trans i18nKey="adminAddreses.replace">Replace</Trans>
              </button>
              {isUploadingAddress ? (
                <button
                  className="btn btn-sm btn-success"
                  title={t('adminAddreses.showProgress', 'show progress')}
                  onClick={this.showUploadProgress}
                >
                  <i className="vtmn-icon_eye_on" />
                </button>
              ) : null}
            </div>
          )}
        </td>
      </tr>
    )
  }

  insertLetter(letter){
    return(
      <button
        key={letter}
        type="button"
        className={`btn aisleLetter btn-sm ${letter === this.state.letter ? 'active' : ''}`}
        onClick={ event => this.filterListByLetter(letter) }
      >
        {letter}
      </button>
    )
  }
}


export default withTranslation()(AdminAddresses);
